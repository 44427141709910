import { Box, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useChangeMailMutation,
  useConfirmChangeMailQuery,
} from "../../../apollo/types";
import AlertFooter from "../../../components/AlertFooter/AlertFooter";

const ChangeEmail: FC = () => {
  const { token } = useParams();

  const [email, setEmail] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [changeMail, { loading }] = useChangeMailMutation({
    onCompleted: (data) => {
      if (data?.changeMail) {
        setSuccessMessage(
          `Vi har sendt dig en email til ${email} med et link til at bekræfte din nye email. Tjek dit spamfilter, hvis du ikke kan finde mailen.`
        );
        setEmail("");
      }
    },
    onError: () => setErrorMessage("Vi kunne ikke skifte din email."),
  });

  const { loading: confirmLoading } = useConfirmChangeMailQuery({
    variables: { token: token || "" },
    skip: !token,
    onCompleted: (data) => {
      if (data?.confirmChangeMail) {
        setSuccessMessage("Din email er blevet skiftet.");
      }
    },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      <Typography>Skift email</Typography>
      <TextField
        disabled={loading || confirmLoading}
        label="Ny email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
      />
      <AlertFooter
        buttons={[
          {
            label: "Skift email",
            onClick: () => changeMail({ variables: { email } }),
            disabled: loading || !email || confirmLoading,
          },
        ]}
        loading={loading || confirmLoading}
        successMessage={successMessage}
        errorMessage={errorMessage}
        onCloseSuccessAlert={() => setSuccessMessage("")}
        onCloseErrorAlert={() => setErrorMessage("")}
      />
    </Box>
  );
};

export default ChangeEmail;
