import { Box, Button } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

const ForgotPasswordLink: FC = () => (
  <Box>
    <Button component={Link} to="/forgot-password">
      Glemt password?
    </Button>
  </Box>
);

export default ForgotPasswordLink;
