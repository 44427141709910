import { TextField } from "@mui/material";
import { FC } from "react";
import useEndLogStore from "../endLogStore";
import ImageUploadSection from "../../../ImageUploadSection/ImageUploadSection";
import ValidFromTo from "../../../ValidFromTo/ValidFromTo";
import { Dayjs } from "dayjs";

type Props = {
  logId: string;
  onImageError: (err: string) => void;
};

const ThirdPartySection: FC<Props> = ({ logId, onImageError }) => {
  const { endLogData, setEndLogData, setImageLoading } = useEndLogStore(
    (state) => state
  );
  const {
    firstName,
    lastName,
    email,
    address,
    journalNo,
    imageUrl,
    validFrom,
    validTo,
  } = endLogData.thirdParty;

  const handleOnChange = (value: string, field: string) => {
    setEndLogData({
      ...endLogData,
      thirdParty: { ...endLogData.thirdParty, [field]: value },
    });
  };

  const handleImageUploaded = (key: string, contentType: string) => {
    setEndLogData({
      ...endLogData,
      thirdParty: {
        ...endLogData.thirdParty,
        imageUrl: key,
        imageType: contentType,
      },
    });
  };

  const handleImageRemove = () => {
    setEndLogData({
      ...endLogData,
      thirdParty: {
        ...endLogData.thirdParty,
        imageUrl: "",
        imageType: "",
      },
    });
  };

  return (
    <>
      <TextField
        label="Fornavn"
        value={firstName}
        onChange={(e) => {
          handleOnChange(e.target.value, "firstName");
        }}
      />
      <TextField
        label="Efternavn"
        value={lastName}
        onChange={(e) => {
          handleOnChange(e.target.value, "lastName");
        }}
      />
      <TextField
        label="Email"
        value={email}
        onChange={(e) => {
          handleOnChange(e.target.value, "email");
        }}
      />
      <TextField
        label="Adresse"
        value={address}
        onChange={(e) => handleOnChange(e.target.value, "address")}
      />
      <TextField
        label="Journal nr"
        value={journalNo}
        onChange={(e) => handleOnChange(e.target.value, "journalNo")}
      />
      <ValidFromTo
        onChange={({ validFrom, validTo }) => {
          setEndLogData({
            ...endLogData,
            thirdParty: { ...endLogData.thirdParty, validFrom, validTo },
          });
        }}
        defaultValidFrom={validFrom}
        defaultValidTo={validTo}
      />
      <ImageUploadSection
        uploadId={logId}
        onImageError={onImageError}
        onImageUploaded={handleImageUploaded}
        onImageUploading={setImageLoading}
        onImageRemove={handleImageRemove}
        hasImage={!!imageUrl}
      />
    </>
  );
};

export default ThirdPartySection;
