import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { PermitByWeaponNoFragment } from "../../../../apollo/types";
import { FC } from "react";
import { grey } from "@mui/material/colors";
import useCreateLogStore from "../createLogStore";

type Props = {
  permits?: PermitByWeaponNoFragment[];
};

const PermitList: FC<Props> = ({ permits }) => {
  const { setCurrentStep, setSelectedPermit, weaponNo } = useCreateLogStore(
    (state) => state
  );
  const handleOnClick = (permit: PermitByWeaponNoFragment) => {
    setCurrentStep("CreateLog");
    setSelectedPermit(permit);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
      <Typography variant="h6">Våben nr: {weaponNo}</Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        {permits?.map((permit) => (
          <ListItem
            key={permit._id}
            disablePadding
            sx={{ border: `1px solid ${grey["400"]}`, borderRadius: 1 }}
          >
            <ListItemButton onClick={() => handleOnClick(permit)}>
              <ListItemText primary={permit.weaponPart || permit.weaponType} />
            </ListItemButton>
            {permit.imageUrl && (
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
            )}
          </ListItem>
        ))}
      </div>
    </div>
  );
};
export default PermitList;
