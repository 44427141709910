import { styled } from "@mui/material";
import { blue } from "@mui/material/colors";

export const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 16,
});

export const BasicInfo = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 12,
});

export const TextButton = styled("span")({
  color: blue[700],
  cursor: "pointer",
});

export const UserTypeContainer = styled("div")({});

export const Footer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 16,
});
