import { Button } from "@mui/material";
import { FC, useState } from "react";
import AuthModal from "../../components/modals/AuthModal/AuthModal";
import Page from "../../layout/Page/Page";

const Admin: FC = () => {
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  return (
    <>
      <Page
        mainCTA={
          <Button variant="contained" onClick={() => setShowAuthModal(true)}>
            Opret virksomhedsbruger
          </Button>
        }
        mainContent={<></>}
      />
      {showAuthModal && (
        <AuthModal
          open={showAuthModal}
          onClose={() => setShowAuthModal(false)}
        />
      )}
    </>
  );
};

export default Admin;
