import { FC } from "react";
import { Props as Item } from "../../../../../components/Menu/MenuItem";
import { styled } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";

import HowToRegIcon from "@mui/icons-material/HowToReg";
import Menu from "../../../../../components/Menu/Menu";

const Root = styled("div")({
  position: "absolute",
  zIndex: 100,
  right: 24,
});

interface Props {
  onClickSeeDetails: () => void;
  onClickAddSubLog?: () => void;
  onClickEnd?: () => void;
}

const MoreMenu: FC<Props> = ({
  onClickSeeDetails,
  onClickAddSubLog,
  onClickEnd,
}) => {
  const items: Item[] = [
    {
      text: "Se detaljer",
      icon: <InfoIcon />,
      onClick: onClickSeeDetails,
    },
    ...(onClickAddSubLog
      ? [
          {
            text: "Flyt",
            icon: <ArrowForwardIcon />,
            onClick: onClickAddSubLog,
          },
        ]
      : []),
    ...(onClickEnd
      ? [{ text: "Afslut", icon: <HowToRegIcon />, onClick: onClickEnd }]
      : []),
  ];

  return (
    <Root>
      <Menu items={items} width={210} maxWidth="100%" />
    </Root>
  );
};

export default MoreMenu;
