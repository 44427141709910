import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useForgotPasswordLazyQuery } from "../../apollo/types";

const ForgotPassword: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [forgotPassword, { loading }] = useForgotPasswordLazyQuery({
    onCompleted: () => {
      setSuccessMessage(
        "Vi har sendt dig en email med et link til at ændre dit password. Tjek dit spamfilter, hvis du ikke kan finde mailen."
      );
      setEmail("");
    },
  });

  return (
    <Container maxWidth="sm">
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h5">Glemt password?</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Typography variant="body1">
            Indtast din mail, og vi sender dig et link til at ændre dit password
          </Typography>
          <TextField
            label="Email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <Box>
            <Button
              onClick={() => forgotPassword({ variables: { email } })}
              variant="contained"
              disabled={!email || loading}
            >
              {loading ? (
                <CircularProgress color={"info"} size={20} />
              ) : (
                "Send mail"
              )}
            </Button>
          </Box>
          {!!successMessage && (
            <Alert
              sx={{ zIndex: 10 }}
              severity="success"
              onClose={() => setSuccessMessage("")}
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
