import { TextField, styled } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";

export const Root = styled("div")({
  display: "flex",
  flecDirection: "row",
  gap: 12,
  alignContent: "stretch",
});

export const DatePicker = styled(DesktopDatePicker)({
  flex: 1,
}) as any;

type Props = {
  disabled?: boolean;
  defaultValidFrom?: Dayjs | null;
  defaultValidTo?: Dayjs | null;
  onChange: (value: { validFrom: Dayjs; validTo: Dayjs }) => void;
};

const ValidFromTo: FC<Props> = ({
  disabled,
  defaultValidFrom,
  defaultValidTo,
  onChange,
}) => {
  const [validFrom, setValidFrom] = useState<Dayjs>(
    defaultValidFrom ?? dayjs()
  );
  const [validTo, setValidTo] = useState<Dayjs>(defaultValidTo ?? dayjs());

  const handleSetValidFrom = (value: Dayjs) => {
    setValidFrom(value);
    // add 10 years to validTo
    if (value) {
      setValidTo(value.add(10, "year"));
    }
  };

  const handleSetValidTo = (value: Dayjs) => {
    setValidTo(value);
  };

  useEffect(() => {
    onChange({ validFrom, validTo });
  }, [validFrom, validTo]);
  return (
    <Root>
      <DatePicker
        disabled={disabled}
        label="Gyldig fra"
        inputFormat="DD/MM/YYYY"
        value={validFrom}
        onChange={(value: Dayjs) => handleSetValidFrom(value)}
        renderInput={(params: any) => <TextField {...params} />}
      />
      <DatePicker
        disabled={disabled}
        label="Gyldig til"
        inputFormat="DD/MM/YYYY"
        value={validTo}
        onChange={(value: Dayjs) => handleSetValidTo(value)}
        renderInput={(params: any) => <TextField {...params} />}
      />
    </Root>
  );
};

export default ValidFromTo;
