import { Typography } from "@mui/material";
import DetailsCard from "../DetailsCard/DetailsCard";
import dayjs from "dayjs";
import { FC } from "react";

type Props = {
  validFrom?: string;
  validTo?: string;
  journalNo: string;
};

const ValidPeriodDetailsCard: FC<Props> = ({
  validFrom,
  validTo,
  journalNo,
}) => (
  <DetailsCard>
    <Typography variant="body1" mb={1}>
      Tilladelse:
    </Typography>
    {validFrom && (
      <Typography variant="body2">
        Gyldig fra: {dayjs(validFrom).format("DD/MM/YYYY")}
      </Typography>
    )}
    {validTo && (
      <Typography variant="body2">
        Gyldig til: {dayjs(validTo).format("DD/MM/YYYY")}
      </Typography>
    )}
    <Typography variant="body2">Jornalnr: {journalNo}</Typography>
  </DetailsCard>
);

export default ValidPeriodDetailsCard;
