import { FC, useEffect, useState } from "react";
import {
  LogRowFragment,
  MyLogType,
  useGetMyLogsV2LazyQuery,
  useMeQuery,
} from "../../apollo/types";
import Page from "../../layout/Page/Page";
import LogTable from "./components/LogTable/LogTable";
import Header from "./components/Header/Header";

const Logs: FC = () => {
  const [logsSelected, setLogsSelected] = useState<MyLogType>(MyLogType.Active);

  const { data: me } = useMeQuery();

  const [getMyLogsV2, { data }] = useGetMyLogsV2LazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getMyLogsV2({
      variables: {
        input: {
          logType: logsSelected,
        },
      },
    });
  }, [logsSelected]);

  return (
    <>
      {me?.me && (
        <div className="logs" style={{ paddingBottom: "120px" }}>
          <Page
            mainCTA={
              <Header
                logsSelected={logsSelected}
                onChangeLogsSelected={(l) => setLogsSelected(l)}
              />
            }
            mainContent={
              <LogTable
                rows={(data?.getMyLogsV2 as LogRowFragment[]) || []}
                onClickRow={(id) => console.log(id)}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default Logs;
