import { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useMeQuery, UserType } from "../../../apollo/types";

interface Props {
  navigateTo: string;
  children: ReactElement;
  blockedType?: UserType;
  adminRoute?: boolean;
}

const ProtectedRoute: FC<Props> = ({
  navigateTo,
  children,
  blockedType = UserType.Private,
  adminRoute = false,
}) => {
  const { data: me } = useMeQuery();
  const location = useLocation();

  if (!me?.me) return null;

  return (adminRoute && !me?.me?.admin) ||
    (!adminRoute && me?.me?.userType === blockedType) ? (
    <Navigate to={navigateTo} state={{ from: location }} replace />
  ) : (
    children
  );
};

export default ProtectedRoute;
