import { Box, Container, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MyLogType, useLoginMutation, useMeQuery } from "../../apollo/types";
import AlertFooter from "../../components/AlertFooter/AlertFooter";
import ForgotPasswordLink from "../../components/ForgotPasswordLink/ForgotPasswordLink";
import { TextButton } from "../../components/modals/AuthModal/AuthModal.styles";
import PasswordField from "../../components/PasswordField/PasswordField";
import { GET_PERMITS } from "../permits/getPermits";
import { ME_QUERY } from "../root/me";
import { GET_MY_LOGS_V2 } from "../weaponLogs/queries/getMyLogsV2";

const Login: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const { data: me } = useMeQuery();

  const navigate = useNavigate();

  const [login, { loading }] = useLoginMutation({
    onCompleted: (data) => {
      // Login failed
      if (!data.login) {
        setErrorMessage("Login mislykkedes - email eller password forkert");
      }
    },
  });

  const handleOnClick = async () => {
    login({
      variables: { email, password },
      // TODO: figure out if this is needed
      refetchQueries: [
        { query: ME_QUERY },
        { query: GET_PERMITS },
        {
          query: GET_MY_LOGS_V2,
          variables: {
            input: {
              logType: MyLogType.Active,
            },
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (!!me?.me) {
      navigate("/permits");
    }
  }, [me]);

  return (
    <Container maxWidth="sm">
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h5">Log ind</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <PasswordField
              id="password-input"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ForgotPasswordLink />
          </Box>
          <Typography textAlign="center">
            Ingen bruger?{" "}
            <TextButton onClick={() => navigate("/register")}>
              Opret dig
            </TextButton>
          </Typography>
          <AlertFooter
            buttons={[
              {
                label: "Log ind",
                onClick: handleOnClick,
                disabled: !email || !password || loading,
              },
            ]}
            loading={loading}
            successMessage={successMessage}
            errorMessage={errorMessage}
            onCloseSuccessAlert={() => setSuccessMessage("")}
            onCloseErrorAlert={() => setErrorMessage("")}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
