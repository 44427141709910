import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { FC } from "react";

type Props = {
  label: string;
  loading?: boolean;
} & ButtonProps;

const LoadingButton: FC<Props> = ({ loading, label, ...props }) => (
  <Button {...props}>
    {loading ? <CircularProgress color={"info"} size={20} /> : label}
  </Button>
);

export default LoadingButton;
