import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import Fuse from "fuse.js";
import { TextField, TextFieldProps, styled } from "@mui/material";
import manufacturers from "./manufacturers";
import calibers from "./calibers";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  position: "relative",
});

const Results = styled("div")({
  top: "60px",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  maxHeight: "200px",
  width: "100%",
  position: "absolute",
  overflowY: "auto",
  zIndex: 10,
  background: "white",
  borderRadius: "4px",
});

const Result = styled("div")(({ theme }) => ({
  cursor: "pointer",
  fontFamily: theme.typography.fontFamily,
  padding: "8px",
  borderRadius: "4px",
  "&:hover": {
    background: theme.palette.grey[200],
  },
}));

const options = {
  includeScore: true,
  threshold: 0.2, // Adjust this value for search sensitivity
  keys: [""],
};

export type SelectType = "calibers" | "manufacturers";

type Props = {
  selectType: SelectType;
  onSelectResult: (value: string) => void;
} & TextFieldProps;

const FuzzySearchSelect: FC<Props> = ({
  selectType,
  onSelectResult,
  ...props
}) => {
  const [query, setQuery] = useState<string>("");
  const [openResults, setOpenResults] = useState<boolean>(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setOpenResults(false));

  useEffect(() => {
    onSelectResult(query);
  }, [query]);

  const list: string[] = useMemo(
    () => (selectType === "calibers" ? calibers : manufacturers),
    [selectType]
  );

  const fuse = useMemo(() => new Fuse(list, options), [list, options]);

  const results = useMemo(() => fuse.search(query), [fuse, query]);

  const handleOnClickResult = (value: string) => {
    setQuery(value);
    onSelectResult(value);
    setOpenResults(false);
  };

  const handleOnClickTextField = () => {
    if (results.length > 0) setOpenResults(true);
  };

  return (
    <Root ref={ref}>
      <TextField
        {...props}
        autoComplete="off"
        value={query}
        onChange={(e) => {
          setOpenResults(true);
          setQuery(e.target.value);
        }}
        onClick={handleOnClickTextField}
      />
      <Results>
        {results.length > 0 &&
          openResults &&
          results.map((result) => (
            <Result
              key={result.item}
              onClick={() => handleOnClickResult(result.item)}
            >
              {result.item}
            </Result>
          ))}
      </Results>
    </Root>
  );
};

export default FuzzySearchSelect;
