import { gql } from "@apollo/client";
import { LogRow } from "./LogRow";

export const GET_MY_LOGS_V2 = gql`
  ${LogRow}
  query getMyLogsV2($input: GetMyLogsInput!) {
    getMyLogsV2(input: $input) {
      ...LogRow
    }
  }
`;
