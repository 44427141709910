import { useMemo } from "react";

const useIsEmail = (email: string) => {
  const isEmail = useMemo(
    () => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email),
    [email]
  );

  return { isEmail };
};

export default useIsEmail;
