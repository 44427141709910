import { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useMeQuery } from "../../../apollo/types";

interface Props {
  navigateTo: string;
  children: ReactElement;
}

const ConfirmedRoute: FC<Props> = ({ children }) => {
  const { data: me } = useMeQuery();
  const location = useLocation();

  return me?.me?.confirmed ? (
    <Navigate to="/permits" state={{ from: location }} replace />
  ) : (
    children
  );
};

export default ConfirmedRoute;
