import { Button, IconButton, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";
import {
  PermitUser,
  useCreateSubPermitMutation,
  useEditPermitMutation,
  useGetPermitByIdQuery,
  useMeQuery,
} from "../../../apollo/types";
import { GET_PERMITS } from "../../../routes/permits/getPermits";
import Modal from "../Modal/Modal";
import { DatePicker, ManufacturerModel } from "../Modal/Shared.styles";
import { Content, Footer } from "./CreateSubPermit.styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import useUploadImage from "../../../hooks/useUploadImage";
import ValidFromTo from "../../ValidFromTo/ValidFromTo";

interface Props {
  open: boolean;
  onClose: () => void;
  id?: string;
}

const CreateSubPermitModal: FC<Props> = ({ open, onClose, id }) => {
  const [caliber, setCaliber] = useState<string>("");
  const [manufacturer, setManufacturer] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [storageAddress, setStorageAddress] = useState<string>("");
  const [validFrom, setValidFrom] = useState<Dayjs | null>(dayjs());
  const [validTo, setValidTo] = useState<Dayjs | null>(dayjs());
  const [weaponNo, setWeaponNo] = useState<string>("");
  const [weaponType, setWeaponType] = useState<string>("");
  const [weaponPart, setWeaponPart] = useState<string>("");
  const [permitUser, setPermitUser] = useState<PermitUser>(PermitUser.Owner);
  const [journalNo, setJournalNo] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);

  const {
    data,
    loading,
    error: getPermitError,
  } = useGetPermitByIdQuery({
    skip: !id,
    variables: { id: id! },
  });

  const { data: me } = useMeQuery();

  useEffect(() => {
    setCaliber(data?.getPermitById.caliber || "");
    setManufacturer(data?.getPermitById.manufacturer || "");
    setModel(data?.getPermitById.model || "");
    setStorageAddress(data?.getPermitById.storageAddress || "");
    setValidFrom(data?.getPermitById.validFrom || dayjs());
    setValidTo(data?.getPermitById.validTo || dayjs());
    setWeaponNo(data?.getPermitById.weaponNo || "");
    setWeaponType(data?.getPermitById.weaponType || "");
    setJournalNo(data?.getPermitById.journalNo || "");
    setPermitUser(
      data?.getPermitById.owner?._id === me?.me?._id
        ? PermitUser.Owner
        : PermitUser.User
    );
  }, [data]);

  const [createSubPermit] = useCreateSubPermitMutation({
    onCompleted: async (data) => {
      if (data && image) {
        await handleUploadImage(data.createSubPermit._id, image);
      }
      handleOnClose();
    },
    onError: (error) => setError(error.message),
    refetchQueries: [{ query: GET_PERMITS }],
  });

  const [edit, { loading: editLoading }] = useEditPermitMutation();

  const { handleUploadImage, loading: imageLoading } = useUploadImage(
    (key, contentType, permitId) =>
      edit({
        variables: {
          input: { permitId, imageUrl: key, imageType: contentType },
        },
      }),
    (err) => setError(err)
  );

  const handleOnClose = () => {
    setCaliber("");
    setManufacturer("");
    setModel("");
    setStorageAddress("");
    setValidFrom(dayjs());
    setValidTo(dayjs());
    setWeaponNo("");
    setWeaponType("");
    setPermitUser(PermitUser.Owner);
    setJournalNo("");
    setWeaponPart("");
    onClose();
  };

  const handleLeftButtonClick = () => {
    handleOnClose();
  };

  const handleRightButtonClick = () => {
    createSubPermit({
      variables: {
        input: {
          caliber,
          manufacturer,
          model,
          storageAddress,
          validFrom,
          validTo,
          weaponNo,
          weaponType,
          permitUser,
          rootPermitId: id,
          journalNo,
          weaponPart,
        },
      },
    });
  };

  return (
    <Modal
      error={error}
      onDismissError={() => setError("")}
      open={open}
      onClose={handleOnClose}
      title="Opret del-tilladelse"
      width={680}
    >
      <Content>
        <Button
          component="label"
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Upload billede
          <input
            type="file"
            hidden
            accept="image/*, .pdf, .heic, .heif, image/heic, image/heif"
            onChange={(e) => setImage(e.target.files && e.target.files[0])}
            disabled={loading}
          />
        </Button>
        {!!image && (
          <div style={{ display: "flex", gap: 1, alignItems: "center" }}>
            <CheckIcon color="success" />
            <Typography>{image.name}</Typography>
            <IconButton
              aria-label="Remove image button"
              onClick={() => setImage(null)}
              onMouseDown={() => setImage(null)}
            >
              <CloseIcon color="error" />
            </IconButton>
          </div>
        )}
        <TextField
          label="Våbendel"
          value={weaponPart}
          onChange={(e) => setWeaponPart(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <TextField
          label="Våbenart/Type"
          value={weaponType}
          onChange={(e) => setWeaponType(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <ManufacturerModel>
          <TextField
            sx={{ flex: 2 }}
            label="Fabrikat"
            value={manufacturer}
            onChange={(e) => setManufacturer(e.target.value)}
            fullWidth
            disabled={loading}
          />
          <TextField
            sx={{ flex: 1 }}
            label="Model"
            value={model}
            onChange={(e) => setModel(e.target.value)}
            fullWidth
            disabled={loading}
          />
        </ManufacturerModel>
        <TextField
          label="Våben nr."
          value={weaponNo}
          onChange={(e) => setWeaponNo(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <TextField
          label="Kaliber"
          value={caliber}
          onChange={(e) => setCaliber(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <TextField
          label="Journal nr."
          value={journalNo}
          onChange={(e) => setJournalNo(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <ValidFromTo
          onChange={({ validFrom, validTo }) => {
            setValidFrom(validFrom);
            setValidTo(validTo);
          }}
          defaultValidFrom={validFrom}
          defaultValidTo={validTo}
          disabled={loading}
        />
        <TextField
          label="Opbevaringsadresse"
          value={storageAddress}
          onChange={(e) => setStorageAddress(e.target.value)}
          fullWidth
          disabled={loading}
        />
      </Content>
      <Footer>
        <Button variant="outlined" onClick={handleLeftButtonClick}>
          Luk
        </Button>
        <Button
          variant="contained"
          onClick={handleRightButtonClick}
          disabled={
            !caliber ||
            !manufacturer ||
            !model ||
            !storageAddress ||
            !validFrom ||
            !validTo ||
            !weaponNo ||
            !journalNo ||
            !weaponPart ||
            loading
          }
        >
          Gem
        </Button>
      </Footer>
    </Modal>
  );
};

export default CreateSubPermitModal;
