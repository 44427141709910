import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";
import {
  useEditAndUpdateSubPermitMutation,
  useGetPermitByIdQuery,
} from "../../../apollo/types";
import { GET_PERMITS } from "../../../routes/permits/getPermits";
import Modal from "../Modal/Modal";
import { Info } from "../EditPermitModal/EditPermitModal.styles";
import { ManufacturerModel } from "../Modal/Shared.styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import useUploadImage from "../../../hooks/useUploadImage";
import ValidFromTo from "../../ValidFromTo/ValidFromTo";

interface Props {
  open: boolean;
  onClose: () => void;
  editPermitId: string;
  rootPermitId: string;
}

const EditSubPermitModal: FC<Props> = ({
  open,
  onClose,
  editPermitId,
  rootPermitId,
}) => {
  const [caliber, setCaliber] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [manufacturer, setManufacturer] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [storageAddress, setStorageAddress] = useState<string>("");
  const [validFrom, setValidFrom] = useState<Dayjs | null>(dayjs());
  const [validTo, setValidTo] = useState<Dayjs | null>(dayjs());
  const [weaponNo, setWeaponNo] = useState<string>("");
  const [weaponType, setWeaponType] = useState<string>("");
  const [weaponPart, setWeaponPart] = useState<string>("");
  const [journalNo, setJournalNo] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const {
    data,
    error: getPermitError,
    loading: getPermitLoading,
    refetch,
  } = useGetPermitByIdQuery({
    variables: { id: editPermitId },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  useEffect(() => {
    setCaliber(data?.getPermitById.caliber || "");
    setManufacturer(data?.getPermitById.manufacturer || "");
    setModel(data?.getPermitById.model || "");
    setStorageAddress(data?.getPermitById.storageAddress || "");
    setValidFrom(data?.getPermitById.validFrom || dayjs());
    setValidTo(data?.getPermitById.validTo || dayjs());
    setWeaponNo(data?.getPermitById.weaponNo || "");
    setWeaponType(data?.getPermitById.weaponType || "");
    setJournalNo(data?.getPermitById.journalNo || "");
    setImageUrl(data?.getPermitById.imageUrl || "");
    setWeaponPart(data?.getPermitById.weaponPart || "");
  }, [data]);

  const [edit] = useEditAndUpdateSubPermitMutation({
    onCompleted: async (data) => {
      if (data && image) {
        await handleUploadImage(data.editAndUpdateSubPermit._id, image);
      }
      setLoading(false);
      handleOnClose();
    },
    onError: (error) => setError(error.message),
    refetchQueries: [{ query: GET_PERMITS }],
  });

  const { handleUploadImage, loading: imageLoading } = useUploadImage(
    (key, contentType, permitId) =>
      edit({
        variables: {
          input: {
            permitId,
            imageUrl: key,
            imageType: contentType,
            rootPermitId,
          },
        },
      }),
    (err) => setError(err)
  );

  const handleOnClose = () => {
    onClose();
  };

  const handleSave = () => {
    setLoading(true);
    edit({
      variables: {
        input: {
          permitId: editPermitId,
          caliber,
          imageUrl,
          manufacturer,
          model,
          storageAddress,
          validFrom,
          validTo,
          weaponNo,
          weaponType,
          journalNo,
          rootPermitId,
          weaponPart,
        },
      },
    });
  };

  return (
    <Modal
      error={error}
      onDismissError={() => setError("")}
      open={open}
      onClose={handleOnClose}
      title={`Redigér tilladelse`}
      width={680}
      sx={{
        "& .MuiDialog-paper": {
          gap: "24px",
        },
      }}
    >
      <Info>
        <Button
          component="label"
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Upload{!!imageUrl ? " nyt" : ""} billede
          <input
            type="file"
            hidden
            accept="image/*, .pdf, .heic, .heif, image/heic, image/heif"
            onChange={(e) => setImage(e.target.files && e.target.files[0])}
            disabled={loading}
          />
        </Button>
        {!!image && (
          <div style={{ display: "flex", gap: 1, alignItems: "center" }}>
            <CheckIcon color="success" />
            <Typography>{image.name}</Typography>
            <IconButton
              aria-label="Remove image button"
              onClick={() => setImage(null)}
              onMouseDown={() => setImage(null)}
            >
              <CloseIcon color="error" />
            </IconButton>
          </div>
        )}
        <TextField
          label="Våbendel"
          value={weaponPart}
          onChange={(e) => setWeaponPart(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <TextField
          label="Våbenart/Type"
          value={weaponType}
          onChange={(e) => setWeaponType(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <ManufacturerModel>
          <TextField
            sx={{ flex: 2 }}
            label="Fabrikat"
            value={manufacturer}
            onChange={(e) => setManufacturer(e.target.value)}
            fullWidth
            disabled={loading}
          />
          <TextField
            sx={{ flex: 1 }}
            label="Model"
            value={model}
            onChange={(e) => setModel(e.target.value)}
            fullWidth
            disabled={loading}
          />
        </ManufacturerModel>
        <TextField
          label="Våben nr."
          value={weaponNo}
          onChange={(e) => setWeaponNo(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <TextField
          label="Kaliber"
          value={caliber}
          onChange={(e) => setCaliber(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <TextField
          label="Journal nr."
          value={journalNo}
          onChange={(e) => setJournalNo(e.target.value)}
          fullWidth
          disabled={loading}
        />
        <ValidFromTo
          disabled={loading}
          defaultValidFrom={validFrom}
          defaultValidTo={validTo}
          onChange={({ validFrom, validTo }) => {
            setValidFrom(validFrom);
            setValidTo(validTo);
          }}
        />
        <TextField
          label="Opbevaringsadresse"
          value={storageAddress}
          onChange={(e) => setStorageAddress(e.target.value)}
          fullWidth
          disabled={loading}
        />
      </Info>
      <Button variant="contained" onClick={handleSave} disabled={loading}>
        {loading ? <CircularProgress color={"info"} size={20} /> : "Gem"}
      </Button>
    </Modal>
  );
};

export default EditSubPermitModal;
