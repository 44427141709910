import { FC, ReactElement } from "react";
import { ListItemIcon, ListItemText, MenuItem as Root } from "@mui/material";

export interface Props {
  icon?: ReactElement;
  text: string;
  onClick: () => void;
}

const MenuItem: FC<Props> = ({ icon, text, onClick }) => {
  return (
    <Root onClick={onClick} sx={{ padding: 1.5, display: "flex" }}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText sx={{ textAlign: "left" }}>{text}</ListItemText>
    </Root>
  );
};

export default MenuItem;
