import { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { Permit, useGetLogDetailsQuery } from "../../../apollo/types";
import Modal from "../Modal/Modal";
import PermitDetailsCard from "../../PermitDetailsCard/PermitDetailsCard";
import DetailsCard from "../../DetailsCard/DetailsCard";
import ValidPeriodDetailsCard from "../../ValidPeriodDetailsCard/ValidPeriodDetailsCard";
import { CardSection, Content, InnerCard } from "./SeeLogDetailsModal.styles";

type Props = {
  open: boolean;
  onClose: () => void;
  id: string;
};

const SeeLogDetailsModal: FC<Props> = ({ open, onClose, id }) => {
  const { data } = useGetLogDetailsQuery({
    variables: { id },
    skip: !id,
  });

  const { getLogById } = data || {};
  const { permit, givingUser, receivingUser, currentAddress, inputDate, note } =
    getLogById || {};
  const { fullName: givingUserName } = givingUser || {};
  const { fullName: receivingUserName } = receivingUser || {};
  const { validFrom, validTo, journalNo } = permit || {};

  return (
    <Modal title="Se detaljer" open={open} onClose={onClose}>
      <Content>
        <DetailsCard>
          <InnerCard>
            <CardSection>
              <Typography variant="body1">Kom fra:</Typography>
              <Typography variant="body2">{givingUserName}</Typography>
            </CardSection>
            <CardSection>
              <Typography variant="body1">Gik til:</Typography>
              <Typography variant="body2">{receivingUserName}</Typography>
            </CardSection>
          </InnerCard>
          <Typography variant="body1">Nuværende addresse:</Typography>
          <Typography variant="body2">{currentAddress}</Typography>
        </DetailsCard>
        <PermitDetailsCard permit={permit as Permit} />
        <ValidPeriodDetailsCard
          validFrom={validFrom || ""}
          validTo={validTo || ""}
          journalNo={journalNo || ""}
        />
        <DetailsCard>
          <Typography mb={1} variant="body1">
            Dato:
          </Typography>
          <Typography variant="body2">
            {dayjs(inputDate).utc(true).format("DD/MM/YYYY")}
          </Typography>
        </DetailsCard>
        {data?.getLogById.note && (
          <DetailsCard>
            <Typography variant="body1" mb={1}>
              Note:
            </Typography>
            <Typography variant="body2">{note}</Typography>
          </DetailsCard>
        )}
      </Content>
    </Modal>
  );
};

export default SeeLogDetailsModal;
