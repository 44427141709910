import {
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
} from "@mui/material";
import { FC, useState } from "react";
import Modal from "../Modal/Modal";
import { Dayjs } from "dayjs";
import { DatePicker } from "../Modal/Shared.styles";
import { EndLogType, useMeQuery } from "../../../apollo/types";
import ThirdPartySection from "./ThirdPartySection/ThirdPartySection";
import useEndLogStore from "./endLogStore";
import TextArea from "../../TextArea/TextArea";
import useEndLog from "./useEndLog";

const Owner = styled("span")({
  fontWeight: "bold",
});

type Props = {
  open: boolean;
  ownerName: string;
  onClose: () => void;
  logId: string;
};

const EndLogModal: FC<Props> = ({ open, ownerName, onClose, logId }) => {
  const endType = useEndLogStore((state) => state.endType);
  const imageLoading = useEndLogStore((state) => state.imageLoading);
  const endLogData = useEndLogStore((state) => state.endLogData);
  const { inputDate, note, thirdParty } = endLogData;
  const { address, imageUrl, journalNo, firstName, lastName, email } =
    thirdParty;
  const setEndLogData = useEndLogStore((state) => state.setEndLogData);
  const setEndType = useEndLogStore((state) => state.setEndType);
  const resetStore = useEndLogStore((state) => state.resetStore);
  const [error, setError] = useState<string>("");

  const { data: me } = useMeQuery();

  const handleOnClose = () => {
    resetStore();
    onClose();
  };

  const { endLog } = useEndLog(handleOnClose);
  const handleEndLog = () => {
    endLog(logId, me?.me?._id || "");
  };

  const getDisabled = () => {
    if (imageLoading) {
      return true;
    }
    if (endType === EndLogType.ThirdParty) {
      return (
        !inputDate ||
        !note ||
        !address ||
        !imageUrl ||
        !journalNo ||
        !firstName ||
        !lastName ||
        !email
      );
    }

    return !inputDate || !note;
  };

  return (
    <Modal
      error={error}
      onDismissError={() => setError("")}
      open={open}
      onClose={handleOnClose}
      title={"Afslut log?"}
      width={680}
    >
      <RadioGroup
        defaultValue={EndLogType.Owner}
        name="end-to-buttons-group"
        onChange={(_, value) => setEndType(value as EndLogType)}
      >
        <FormControlLabel
          value={EndLogType.Owner}
          control={<Radio color="secondary" />}
          label={
            <>
              Aflever våben tilbage til <Owner>{ownerName}</Owner>
            </>
          }
        />
        <FormControlLabel
          value={EndLogType.ThirdParty}
          control={<Radio color="secondary" />}
          label="Aflever våben til 3. part"
        />
      </RadioGroup>
      {endType === EndLogType.ThirdParty && (
        <ThirdPartySection
          logId={logId}
          onImageError={(error) => setError(error)}
        />
      )}
      <DatePicker
        label="Dato"
        inputFormat="DD/MM/YYYY"
        value={inputDate}
        onChange={(value: Dayjs) =>
          setEndLogData({ ...endLogData, inputDate: value })
        }
        renderInput={(params: any) => <TextField {...params} />}
      />
      <TextArea
        required
        minRows={3}
        placeholder="Note"
        value={note}
        onChange={(e) => setEndLogData({ ...endLogData, note: e.target.value })}
      />
      <Button
        variant="contained"
        onClick={handleEndLog}
        disabled={getDisabled()}
      >
        Afslut log
      </Button>
    </Modal>
  );
};

export default EndLogModal;
