import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  CorporateClientFragment,
  useGetCorporateClientsQuery,
} from "../../../../../../apollo/types";
import { FC, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

type Props = {
  onSelectCorporateClient: (corporateClient: CorporateClientFragment) => void;
  onClickAddNewCorporateClient: () => void;
};

const CorporateClientSelect: FC<Props> = ({
  onSelectCorporateClient,
  onClickAddNewCorporateClient,
}) => {
  const { data } = useGetCorporateClientsQuery({ fetchPolicy: "network-only" });
  const { getCorporateClients: corporateClients } = data || {};
  const [clientId, setClientId] = useState<string>("");

  const handleOnClickCorporateClient = (id: string) => {
    setClientId(id);
    const corporateClient = corporateClients?.find(
      (client) => client._id === id
    );
    if (corporateClient) {
      onSelectCorporateClient(corporateClient);
    }
  };

  return (
    <>
      {!!corporateClients?.length && (
        <>
          <FormControl>
            <InputLabel id="corporate-clients-select-label">
              Navn på kunde
            </InputLabel>
            <Select
              labelId="corporate-clients-select-label"
              id="corporate-clients-select"
              value={clientId}
              label="Navn på kunde"
              onChange={(e) =>
                handleOnClickCorporateClient(e.target.value as string)
              }
            >
              {corporateClients?.map((client) => (
                <MenuItem key={client._id} value={client._id}>
                  {client.corporateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!!clientId && (
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="outlined"
              onClick={() => {
                setClientId("");
                onClickAddNewCorporateClient();
              }}
            >
              Tilføj ny virksomhed
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default CorporateClientSelect;
