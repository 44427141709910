import { FC, Fragment, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PermitRowFragment } from "../../../../apollo/types";
import PermitTableRow from "./PermitTableRow/PermitTableRow";
import SubPermitTableRow from "./SubPermitTableRow/SubPermitTableRow";

interface Props {
  rows: PermitRowFragment[];
  onClickRow: (id: string) => void;
  onClickEditSubPermit: (subpermitId: string, permitId: string) => void;
  onClickAddSubPermit: (id: string) => void;
  onClickSeeDetails: (id: string) => void;
}

const PermitTable: FC<Props> = ({
  rows,
  onClickRow,
  onClickEditSubPermit,
  onClickAddSubPermit,
  onClickSeeDetails,
}) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Våbennr</TableCell>
              <TableCell>Fabrikat + model</TableCell>
              <TableCell>Våbentype</TableCell>
              <TableCell>Våbendel</TableCell>
              <TableCell>Kaliber</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 &&
              rows.map((row, index) => {
                if (!row.isSubPermit)
                  return (
                    <Fragment key={row._id}>
                      <PermitTableRow
                        key={`${row._id}${index}`}
                        onClickEdit={() => onClickRow(row._id)}
                        caliber={row.caliber}
                        onClickAddSubPermit={() => onClickAddSubPermit(row._id)}
                        onClickSeeDetails={() => onClickSeeDetails(row._id)}
                        weaponType={row.weaponType}
                        isHome={row.isHome || false}
                        weaponNo={row.weaponNo}
                        manufacturer={row.manufacturer}
                        model={row.model}
                      />
                      {!!row.subPermits?.length &&
                        row.subPermits.map((subPermit, index) => (
                          <SubPermitTableRow
                            key={`${subPermit._id}-${index}`}
                            caliber={subPermit.caliber}
                            weaponPart={subPermit.weaponPart || ""}
                            weaponType={subPermit.weaponType}
                            onClickEdit={() =>
                              onClickEditSubPermit(subPermit._id, row._id)
                            }
                            isHome={subPermit.isHome || false}
                            onClickSeeDetails={() =>
                              onClickSeeDetails(subPermit._id)
                            }
                            weaponNo={subPermit.weaponNo}
                            manufacturer={subPermit.manufacturer}
                            model={subPermit.model}
                          />
                        ))}
                    </Fragment>
                  );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PermitTable;
