import { gql } from "@apollo/client";

export const GET_PERMIT_BY_ID = gql`
  query getPermitById($id: String!) {
    getPermitById(id: $id) {
      _id
      imageUrl
      imageType
      weaponType
      weaponNo
      manufacturer
      model
      caliber
      storageAddress
      validFrom
      validTo
      journalNo
      weaponPart
      images
      owner {
        _id
      }
      user {
        _id
      }
    }
  }
`;
