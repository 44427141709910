import { Paper } from "@mui/material";
import { FC } from "react";
import MenuItem, { Props as Item } from "./MenuItem";

interface Props {
  width: number | string;
  maxWidth: number | string;
  items: Item[];
}

const Menu: FC<Props> = ({ width, maxWidth, items }) => {
  return (
    <Paper sx={{ width, maxWidth }}>
      {items.map((item, index) => (
        <MenuItem
          text={item.text}
          icon={item.icon}
          key={index}
          onClick={item.onClick}
        />
      ))}
    </Paper>
  );
};

export default Menu;
