const manufacturers = [
  "AC UNITY",
  "AGM",
  "AIMSPORT",
  "AIMZONIC",
  "AIR VENTURE",
  "AIRGUN TECHNOLOGY",
  "AKKAR",
  "ANSCHÜTZ",
  "ANTONIO ZOLI",
  "ARMI BETTINSOLI",
  "ARMI SILMA",
  "ATA ARMS",
  "A-TEC",
  "ATN",
  "AYA",
  "B&T",
  "BAIKAL",
  "BENELLI",
  "BERETTA",
  "BERGARA",
  "BERTHIER",
  "BIRMINGHAM",
  "BLASER",
  "BREDA",
  "BRNO",
  "BROWNELLS",
  "BROWNING",
  "BSA",
  "BUL",
  "CAESAR GUERINI",
  "CANIK",
  "CAPRINUS",
  "CARL GUSTAF",
  "CARL GUSTAV",
  "CHIAPPA FIREARMS",
  "COLT",
  "Crosman",
  "CZ",
  "DAYSTATE",
  "DIANA",
  "DIXIE GUN WORKS",
  "DWM",
  "EDGUN",
  "EDWIN PARRY",
  "ENFIELD",
  "F.LLI PIETTA",
  "FABARM",
  "FAIR",
  "FAT BOY",
  "FAUSTI",
  "FEG",
  "FEINWERKBAU",
  "FLUSSSTAHL KRUPP ESSEN",
  "FRANCHI",
  "FX AIRGUNS",
  "GAMO",
  "GEBRÜDER MERKEL",
  "GECO",
  "GEVELOT",
  "GEVÆRFABRIKKEN KØBENHAVN",
  "GLOCK",
  "GRÜNIG & ELMIGER",
  "GSG",
  "GYTTORP",
  "H S PRECISION",
  "HAENEL",
  "HANYANG",
  "HARRINGTON & RICHARDSON",
  "HATSAN",
  "HAUSKEN",
  "HECKLER & KOCH",
  "HEMBRUG",
  "HERTZ",
  "HEYM",
  "HOWA",
  "HS",
  "HUBEN",
  "HUGLU",
  "HUSQVARNA",
  "HÄMMERLI",
  "HÆRENS TØJHUS",
  "IMI",
  "INFINITY",
  "ISSC",
  "ITHACA",
  "IZHMASH",
  "J. P. SAUER & SOHN",
  "J. PURDEY",
  "JAGUAR",
  "KHAN",
  "KRAG-JØRGENSEN",
  "KRICO",
  "KRIEGHOFF",
  "LAKELANDER",
  "LANBER",
  "LAUGO ARMS",
  "LAURONA",
  "LCS AIRARMS",
  "LEE ENFIELD",
  "LINCOLN",
  "LONGTHORNE",
  "LOTHAR WALTHER",
  "LU-MAR",
  "MANNLICHER",
  "MANUFRANCE",
  "MANURHIN",
  "MARLIN",
  "MAROCCHI",
  "MASTERPIECE ARMS",
  "MAUSER",
  "MAVERICK",
  "MERKEL",
  "MIDLAND GUN CO.",
  "MIROKU",
  "MOSIN NAGANT",
  "MOSSBERG",
  "NIELSEN",
  "NIGHT PEARL",
  "NORICA",
  "NORINCO",
  "OTTERUP",
  "PARD",
  "PARDINI",
  "PARKER HALE",
  "PEDERSOLI",
  "PERAZZI",
  "POF",
  "PULSAR",
  "RAINSON",
  "RCC",
  "REMINGTON",
  "RHEINMETALL",
  "RIZZINI",
  "ROSSI",
  "RUGER",
  "RÖHM",
  "RÖSSLER",
  "SABATTI",
  "SAI",
  "SAKO",
  "SARASQUETA",
  "SAUER",
  "SAVAGE",
  "SCANDIUM",
  "SCHMIDT-RUBIN",
  "SCHULTZ & LARSEN",
  "SEEKINS PRECISION",
  "SIACE",
  "SIG SAUER",
  "SILMA",
  "SIMSON",
  "SKB",
  "Skytteforeningsvåben - ej i PV",
  "SMITH & WESSON",
  "SPRINGFIELD",
  "ST. ETIENNE",
  "STACCATO",
  "STALON",
  "STEEL ACTION",
  "STEINER",
  "STEYR MANNLICHER",
  "STRASSER",
  "SUHL",
  "TECNI-MEC",
  "THOMPSON/CENTER",
  "THUNDER BEAST",
  "TIKKA",
  "TIPPMANN",
  "TOPMARK TRADING",
  "TOZ",
  "TYPHOON",
  "UBERTI",
  "UKENDT",
  "UKENDT BELGISK",
  "UKENDT TYSK",
  "UNIQUE",
  "UNIQUE ALPINE",
  "US CARBINE",
  "USSR",
  "VALMET",
  "VICTRIX",
  "VIKING",
  "VMAC",
  "VOERE",
  "WALTHER",
  "WEATHERBY",
  "WEBLEY & SCOTT",
  "WEIHRAUCH",
  "WHITWORTH",
  "WINCHESTER",
  "YILDIZ",
  "YUKON OPTICS",
  "ZENITCO",
];

export default manufacturers;
