import { Box, Container } from "@mui/material";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useChangeForgotPasswordMutation } from "../../apollo/types";
import AlertFooter from "../../components/AlertFooter/AlertFooter";
import PasswordField from "../../components/PasswordField/PasswordField";

const ChangeForgotPassword: FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>("");

  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [changePassword, { loading }] = useChangeForgotPasswordMutation({
    onCompleted: (data) => {
      if (data) {
        setSuccessMessage("Dit password er skiftet");
        setNewPassword("");
        setRepeatNewPassword("");
        setErrorMessage("");
        navigate("/login");
      } else {
        setErrorMessage("Vi kunne ikke skifte dit password");
      }
    },
    onError: () => setErrorMessage("Vi kunne ikke skifte dit password"),
  });

  return (
    <Container maxWidth="sm">
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        <PasswordField
          id="input-new-password"
          value={newPassword}
          label="Nyt password (min 8 tegn)"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <PasswordField
          id="input-repeat-new-password"
          value={repeatNewPassword}
          onChange={(e) => setRepeatNewPassword(e.target.value)}
          label="Gentag nyt password"
        />
        <AlertFooter
          buttons={[
            {
              label: "Skift password",
              onClick: () =>
                changePassword({
                  variables: {
                    input: {
                      token: token || "",
                      password: newPassword,
                    },
                  },
                }),
              disabled:
                !token ||
                !newPassword ||
                !repeatNewPassword ||
                newPassword !== repeatNewPassword ||
                loading ||
                newPassword.length < 8,
            },
          ]}
          successMessage={successMessage}
          errorMessage={errorMessage}
          onCloseErrorAlert={() => setErrorMessage("")}
          onCloseSuccessAlert={() => setSuccessMessage("")}
        />
      </Box>
    </Container>
  );
};

export default ChangeForgotPassword;
