import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import router from "./routes/router";
import minMax from "dayjs/plugin/minMax";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { client } from "./apollo/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";

dayjs.extend(minMax);
dayjs.extend(utc);

const app = document.getElementById("app");

const theme = createTheme({
  palette: {
    primary: {
      main: "#1F3415",
    },
    background: {
      default: "#FAF7ED",
      paper: "#FAF7ED",
    },
  },
});

if (app) {
  ReactDOM.createRoot(app).render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </LocalizationProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
}
