import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FC, useMemo, useState } from "react";
import {
  PermitRowFragment,
  useGetPermitsQuery,
  useMeQuery,
} from "../../apollo/types";
import CreatePermitModal from "../../components/modals/CreatePermitModal/CreatePermitModal";
import EditPermitModal from "../../components/modals/EditPermitModal/EditPermitModal";
import PermitTable from "./components/PermitTable/PermitTable";
import AddIcon from "@mui/icons-material/Add";
import CreateSubPermitModal from "../../components/modals/CreateSubPermitModal/CreateSubPermitModal";
import Page from "../../layout/Page/Page";
import SeePermitDetailsModal from "../../components/modals/SeePermitDetailsModal/SeePermitDetailsModal";
import EditSubPermitModal from "../../components/modals/EditSubPermitModal/EditSubPermitModal";

enum SelectedPermits {
  ALL = "all",
  USER = "user",
  OWNER = "owner",
}

const Permits: FC = () => {
  const { data: me } = useMeQuery();
  const { data } = useGetPermitsQuery({ skip: !me?.me });

  const [showCreatePermitModal, setShowCreatePermitModal] =
    useState<boolean>(false);
  const [editPermitId, setEditPermitId] = useState<string>("");
  const [subPermitId, setSubPermitId] = useState<string>("");
  const [editSubPermitIds, setEditSubPermitIds] = useState<
    { subPermitId: string; rootPermitId: string } | undefined
  >(undefined);
  const [permitsSelected, setPermitsSelected] = useState<SelectedPermits>(
    SelectedPermits.ALL
  );
  const [permitDetailsId, setPermitDetailsId] = useState<string>("");

  const rows: PermitRowFragment[] = useMemo(() => {
    if (permitsSelected === SelectedPermits.ALL) {
      return data?.getPermits || [];
    }
    return (
      data?.getPermits.filter(
        (permit) => permit[permitsSelected]?._id === me?.me?._id
      ) || []
    );
  }, [data, permitsSelected]);

  const handleOnClickAddSubPermit = (id: string) => {
    setSubPermitId(id);
  };

  return (
    <>
      {me?.me && (
        <div className="permits">
          <Page
            mainCTA={
              <div style={{ display: "flex", gap: "16px" }}>
                <FormControl>
                  <InputLabel id="permits-select-label">
                    Valgte tilladelser
                  </InputLabel>
                  <Select
                    labelId="permits-select-label"
                    id="permits-select"
                    value={permitsSelected}
                    label="Valgte tilladelser"
                    onChange={(e) =>
                      setPermitsSelected(e.target.value as SelectedPermits)
                    }
                  >
                    <MenuItem value={SelectedPermits.ALL}>
                      Alle tilladelser
                    </MenuItem>
                    <MenuItem value={SelectedPermits.OWNER}>
                      Mine tilladelser
                    </MenuItem>
                    <MenuItem value={SelectedPermits.USER}>
                      Bære tilladelser
                    </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  onClick={() => setShowCreatePermitModal(true)}
                  startIcon={<AddIcon />}
                >
                  Opret tilladelse
                </Button>
              </div>
            }
            mainContent={
              rows && (
                <PermitTable
                  onClickAddSubPermit={handleOnClickAddSubPermit}
                  rows={rows || []}
                  onClickRow={(id) => setEditPermitId(id)}
                  onClickSeeDetails={(id) => setPermitDetailsId(id)}
                  onClickEditSubPermit={(subPermitId, rootPermitId) =>
                    setEditSubPermitIds({ subPermitId, rootPermitId })
                  }
                />
              )
            }
          />
        </div>
      )}
      <CreatePermitModal
        open={showCreatePermitModal}
        onClose={() => setShowCreatePermitModal(false)}
      />
      {!!editPermitId && (
        <EditPermitModal
          open={!!editPermitId}
          onClose={() => setEditPermitId("")}
          editPermitId={editPermitId}
        />
      )}
      {!!subPermitId && (
        <CreateSubPermitModal
          open={!!subPermitId}
          onClose={() => setSubPermitId("")}
          id={subPermitId}
        />
      )}
      {!!permitDetailsId && (
        <SeePermitDetailsModal
          open={!!permitDetailsId}
          onClose={() => setPermitDetailsId("")}
          permitId={permitDetailsId}
        />
      )}
      {!!editSubPermitIds && (
        <EditSubPermitModal
          open={!!editSubPermitIds}
          editPermitId={editSubPermitIds.subPermitId}
          rootPermitId={editSubPermitIds.rootPermitId}
          onClose={() => setEditSubPermitIds(undefined)}
        />
      )}
    </>
  );
};

export default Permits;
