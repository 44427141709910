import { FC, useEffect, useState } from "react";
import {
  useEditPermitMutation,
  useGetSignedUrlForGetImageLazyQuery,
} from "../../../apollo/types";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { GET_PERMITS } from "../../../routes/permits/getPermits";
import { GET_PERMIT_BY_ID } from "./getPermitById";

type Props = {
  defaultExpandedImage: string;
  images: string[];
  onClose: () => void;
  permitId: string;
};

const ExpandedImageModal: FC<Props> = ({
  defaultExpandedImage,
  images,
  onClose,
  permitId,
}) => {
  const [imageIndex, setImageIndex] = useState<number>(
    images.findIndex((image) => image === defaultExpandedImage)
  );
  const [getImageUrl, { data: image }] = useGetSignedUrlForGetImageLazyQuery();

  const [imageEdit] = useEditPermitMutation({
    refetchQueries: [
      { query: GET_PERMITS },
      { query: GET_PERMIT_BY_ID, variables: { id: permitId } },
    ],
  });

  const handleDeleteImage = async (image: string) => {
    await imageEdit({
      variables: {
        input: {
          permitId: permitId,
          images: images.filter((i) => i !== image),
        },
      },
    });
    onClose();
  };

  const handleNextImage = () => {
    if (imageIndex < images.length - 1) {
      setImageIndex((prevState) => prevState + 1);
    } else {
      setImageIndex(0);
    }
  };

  const handlePrevImage = () => {
    if (imageIndex > 0) {
      setImageIndex((prevState) => prevState - 1);
    } else {
      setImageIndex(images.length - 1);
    }
  };

  useEffect(() => {
    if (imageIndex >= 0 && defaultExpandedImage) {
      getImageUrl({
        variables: { input: { key: images[imageIndex] } },
        fetchPolicy: "network-only",
      });
    }
  }, [imageIndex, images, defaultExpandedImage]);

  return (
    <Modal open={!!defaultExpandedImage} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "#fff",
          height: "100%",
          padding: "24px",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            color="error"
            variant="outlined"
            startIcon={<DeleteForeverIcon />}
            onClick={() => handleDeleteImage(images[imageIndex])}
          >
            Slet billede
          </Button>
          <IconButton aria-label="close image modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton aria-label="prev image button" onClick={handlePrevImage}>
            <ArrowBackIosIcon />
          </IconButton>
          <Box
            sx={{
              width: "80%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{
                objectFit: "contain",
                width: "100%",
                height: "600px",
                margin: "0 auto",
              }}
              src={image?.getImageUrl.url}
            />
          </Box>
          <IconButton aria-label="next image button" onClick={handleNextImage}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExpandedImageModal;
