import { styled } from "@mui/material";

export const Footer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const StepTwo = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 12,
});
