import { Button, TextareaAutosize } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useState } from "react";
import { MyLogType, useEditNoteMutation } from "../../../apollo/types";
import Modal from "../Modal/Modal";
import { GET_MY_LOGS_V2 } from "../../../routes/weaponLogs/queries/getMyLogsV2";

interface Props {
  open: boolean;
  onClose: () => void;
  logId: string;
}

const AddNoteModal: FC<Props> = ({ open, onClose, logId }) => {
  const [error, setError] = useState<string>("");
  const [note, setNote] = useState<string>("");

  const [editNote] = useEditNoteMutation();

  const handleOnClose = () => {
    setError("");
    setNote("");
    onClose();
  };

  const handleOnClick = () => {
    editNote({
      variables: { input: { logId, note } },
      refetchQueries: [
        {
          query: GET_MY_LOGS_V2,
          variables: {
            input: {
              logType: MyLogType.Active,
            },
          },
        },
      ],
      onCompleted: () => handleOnClose(),
      onError: (err) => setError(err.message),
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={"Tilføj note"}
      error={error}
      onDismissError={() => setError("")}
    >
      <TextareaAutosize
        minRows={3}
        placeholder="Note"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        style={{
          borderColor: grey[400],
          borderRadius: 4,
          padding: 8,
          fontFamily: "Roboto",
          fontSize: "16px",
        }}
      />
      <Button variant="contained" onClick={handleOnClick}>
        Gem
      </Button>
    </Modal>
  );
};

export default AddNoteModal;
