import { FC, useState } from "react";
import Modal from "../Modal/Modal";
import { Info } from "./EditPermitModal.styles";
import Main from "./steps/Main";
import Images from "./Images";

type Step = "Main" | "Images";

interface Props {
  open: boolean;
  onClose: () => void;
  editPermitId: string;
}

const EditPermitModal: FC<Props> = ({ open, onClose, editPermitId }) => {
  const [error, setError] = useState<string>("");
  const [step, setStep] = useState<Step>("Main");

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Modal
      error={error}
      onDismissError={() => setError("")}
      open={open}
      onClose={handleOnClose}
      title={`Redigér tilladelse`}
      width={680}
      sx={{
        "& .MuiDialog-paper": {
          gap: "24px",
        },
      }}
    >
      <Info>
        {step === "Main" && (
          <Main
            onError={setError}
            onClickNext={() => setStep("Images")}
            permitId={editPermitId}
            onClose={handleOnClose}
          />
        )}
        {step === "Images" && (
          <Images
            permitId={editPermitId}
            onError={setError}
            onClickBack={() => setStep("Main")}
          />
        )}
      </Info>
    </Modal>
  );
};

export default EditPermitModal;
