import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { FC } from "react";
import SearchIcon from "@mui/icons-material/Search";
import useCreateLogStore from "../createLogStore";

type Props = {
  onClickGetPermits: (weaponNo: string) => void;
};

const SearchPermit: FC<Props> = ({ onClickGetPermits }) => {
  const { weaponNo, setWeaponNo } = useCreateLogStore((state) => state);

  return (
    <>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-journal-no">
          Våben nr.
        </InputLabel>
        <OutlinedInput
          id="input-journal-no"
          autoFocus
          value={weaponNo}
          onChange={(e) => setWeaponNo(e.target.value)}
          fullWidth
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          label="Journal nr."
        />
      </FormControl>
      <Button variant="contained" onClick={() => onClickGetPermits(weaponNo)}>
        Find tilladelse
      </Button>
    </>
  );
};
export default SearchPermit;
