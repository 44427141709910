import {
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";

const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  justifyContent: "center",
  width: "100%",
});

type Props = { required?: boolean } & TextareaAutosizeProps;

const TextArea: FC<Props> = ({ required, ...props }) => (
  <Root>
    <TextareaAutosize
      {...props}
      style={{
        borderColor: grey[400],
        borderRadius: 4,
        padding: 8,
        fontFamily: "Roboto",
        fontSize: "16px",
      }}
    />
    {required && (
      <Typography variant="caption" color="error">
        * Felt påkrævet
      </Typography>
    )}
  </Root>
);

export default TextArea;
