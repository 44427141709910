import { create } from "zustand";
import { devtools } from "zustand/middleware";
import dayjs, { Dayjs } from "dayjs";
import { EndLogType } from "../../../apollo/types";

type Data = {
  inputDate: Dayjs | null;
  note: string;
  thirdParty: {
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    journalNo: string;
    imageUrl: string;
    imageType: string;
    validFrom: Dayjs | null;
    validTo: Dayjs | null;
  };
};

type State = {
  imageLoading: boolean;
  setImageLoading: (loading: boolean) => void;
  endLogData: Data;
  setEndLogData: (data: Data) => void;
  resetEndLogData: () => void;
  endType: EndLogType;
  setEndType: (type: EndLogType) => void;
  resetStore: () => void;
};

const initialState = {
  inputDate: dayjs(),
  note: "",
  thirdParty: {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    journalNo: "",
    imageUrl: "",
    imageType: "",
    validFrom: dayjs(),
    validTo: dayjs(),
  },
};

const useEndLogStore = create<State>()(
  devtools((set) => ({
    imageLoading: false,
    setImageLoading: (loading) => set({ imageLoading: loading }),
    endLogData: initialState,
    setEndLogData: (data) => set({ endLogData: data }),
    resetEndLogData: () =>
      set({
        endLogData: initialState,
      }),
    endType: EndLogType.Owner,
    setEndType: (type) => {
      set({ endType: type, endLogData: initialState });
    },
    resetStore: () =>
      set({
        imageLoading: false,
        endLogData: initialState,
        endType: EndLogType.Owner,
      }),
  }))
);

export default useEndLogStore;
