import { IconButton, TableCell, TableRow } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { FC, useRef, useState } from "react";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import MoreMenu from "../MoreMenu/MoreMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Status } from "../PermitTable.styles";
import { Dot } from "../../../../../styles/Shared.styles";

interface Props {
  caliber: string;
  weaponType: string;
  weaponPart: string;
  onClickEdit: () => void;
  onClickSeeDetails: () => void;
  isHome?: boolean;
  weaponNo: string;
  manufacturer: string;
  model: string;
}

const SubPermitTableRow: FC<Props> = ({
  caliber,
  weaponPart,
  weaponType,
  onClickEdit,
  onClickSeeDetails,
  isHome,
  weaponNo,
  manufacturer,
  model,
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const ref: any = useRef();

  useOnClickOutside(ref, () => setShowMore(false));

  return (
    <TableRow
      sx={{
        background: grey[100],
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row">
        {weaponNo}
      </TableCell>
      <TableCell component="th" scope="row">
        {manufacturer} {model}
      </TableCell>
      <TableCell component="th" scope="row">
        {weaponType}
      </TableCell>
      <TableCell component="th" scope="row">
        {weaponPart}
      </TableCell>
      <TableCell component="th" scope="row">
        {caliber}
      </TableCell>
      <TableCell component="th" scope="row">
        {isHome ? (
          <Status>
            <Dot size={12} color={green[400]} /> hjemme
          </Status>
        ) : (
          <Status>
            <Dot size={12} color={red[400]} /> ude
          </Status>
        )}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        <div ref={ref}>
          <>
            <IconButton
              aria-label="see more sub permit options"
              onClick={() => setShowMore((prevState) => !prevState)}
            >
              <MoreVertIcon />
            </IconButton>
            {showMore && (
              <MoreMenu
                onClickEdit={onClickEdit}
                onClickSeeDetails={onClickSeeDetails}
              />
            )}
          </>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SubPermitTableRow;
