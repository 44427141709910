import { FC, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import {
  useMeQuery,
  useUpdateEmailSettingsMutation,
} from "../../../apollo/types";
import AlertFooter from "../../../components/AlertFooter/AlertFooter";
import useIsEmail from "../../../utils/hooks/useIsEmail";

const GetAcummulatedLogs: FC = () => {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [weeklyMail, setWeeklyMail] = useState<string>("");
  const { isEmail } = useIsEmail(weeklyMail);

  const { data: me, loading: meLoading } = useMeQuery({
    onCompleted: (data) => {
      if (data?.me?.weeklyMail) {
        setWeeklyMail(data.me.weeklyMail);
      } else {
        setWeeklyMail(data.me?.email || "");
      }
    },
  });

  const resetState = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const [updateEmailSettings, { loading: updateLoading }] =
    useUpdateEmailSettingsMutation();

  const handleOnClick = (subscribe: boolean) => {
    updateEmailSettings({
      variables: {
        input: {
          weeklyMail: weeklyMail,
          getWeeklyMail: subscribe,
        },
      },
      onCompleted: (data) => {
        if (subscribe) {
          if (data) {
            setSuccessMessage("Ugentlige logs er blevet aktiveret.");
          }
        } else {
          if (data) {
            setSuccessMessage("Ugentlige logs er blevet deaktiveret.");
          }
        }
      },
      onError: () => {
        setErrorMessage("Der opstod en fejl.");
      },
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      <Typography>Få ugentlige logs på mail</Typography>
      <TextField
        disabled={meLoading || updateLoading}
        label="Email"
        variant="outlined"
        value={weeklyMail}
        onChange={(e) => setWeeklyMail(e.target.value)}
        fullWidth
        type="email"
      />
      {me?.me?.getWeeklyMail && me?.me?.weeklyMail && (
        <Typography>Ugentlige logs sendes til: {me?.me?.weeklyMail}</Typography>
      )}
      <AlertFooter
        onCloseErrorAlert={resetState}
        onCloseSuccessAlert={resetState}
        successMessage={successMessage}
        errorMessage={errorMessage}
        loading={false}
        buttons={[
          {
            label: `${me?.me?.getWeeklyMail ? "Opdater mail" : "Få logs"}`,
            onClick: () => handleOnClick(true),
            disabled: !isEmail || meLoading || updateLoading,
          },
          ...(me?.me?.getWeeklyMail
            ? [
                {
                  label: "Afmeld",
                  onClick: () => handleOnClick(false),
                  disabled: updateLoading || meLoading,
                  color: "error",
                },
              ]
            : []),
        ]}
      />
    </Box>
  );
};

export default GetAcummulatedLogs;
