import { createBrowserRouter } from "react-router-dom";
import Logs from "./weaponLogs/Logs";
import Permits from "./permits/Permits";
import Root from "./root/Root";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Admin from "./admin/Admin";
import { UserType } from "../apollo/types";
import Confirm from "./confirm/Confirm";
import ConfirmedRoute from "./components/ConfirmedRoute/ConfirmedRoute";
import Settings from "./settings/Settings";
import ChangeForgotPassword from "./changeForgotPassword/ChangeForgotPassword";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import Login from "./login/Login";
import Register from "./register/Register";

export enum Paths {
  PERMITS = "permits",
  LOGS = "logs",
  ADMIN = "admin",
  CONFIRM = "confirm",
  SETTINGS = "settings",
  FORGOT_PASSWORD = "forgot-password",
  CHANGE_FORGOT_PASSWORD = "change-password",
  LOGIN = "login",
  REGISTER = "register",
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    id: "root",
    errorElement: <>Create 404 page</>,
    children: [
      { path: Paths.LOGIN, element: <Login /> },
      { path: Paths.REGISTER, element: <Register /> },
      { path: Paths.PERMITS, element: <Permits /> },
      {
        path: Paths.LOGS,
        element: (
          <ProtectedRoute navigateTo="/permits" blockedType={UserType.Private}>
            <Logs />
          </ProtectedRoute>
        ),
      },
      {
        path: Paths.ADMIN,
        element: (
          <ProtectedRoute navigateTo="/" adminRoute>
            <Admin />
          </ProtectedRoute>
        ),
      },
      {
        path: `${Paths.CONFIRM}/:token`,
        element: (
          <ConfirmedRoute navigateTo="/permits">
            <Confirm />
          </ConfirmedRoute>
        ),
      },
      { path: `${Paths.SETTINGS}`, element: <Settings /> },
      { path: `${Paths.SETTINGS}/:token`, element: <Settings /> },
      {
        path: `${Paths.FORGOT_PASSWORD}`,
        element: <ForgotPassword />,
      },
      {
        path: `${Paths.CHANGE_FORGOT_PASSWORD}/:token`,
        element: <ChangeForgotPassword />,
      },
    ],
  },
]);

export default router;
