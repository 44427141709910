import { gql } from "@apollo/client";

const LogRowPermit = gql`
  fragment LogRowPermit on Permit {
    journalNo
    weaponType
    manufacturer
    caliber
    model
    weaponNo
    owner {
      fullName
    }
    user {
      fullName
    }
  }
`;

const BaseRowLog = gql`
  ${LogRowPermit}
  fragment BaseRowLog on Log {
    _id
    inputDate
    note
    currentAddress
    givingUser {
      fullName
    }
    receivingUser {
      fullName
    }
    permit {
      ...LogRowPermit
    }
  }
`;

export const LogRow = gql`
  ${BaseRowLog}
  fragment LogRow on Log {
    serialNo
    isSubLog
    ...BaseRowLog
    subLogs {
      ...BaseRowLog
    }
  }
`;
