import { Button, styled } from "@mui/material";
import useCreateLogStore from "../createLogStore";

const Root = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "2rem",
}));

const ChooseType = () => {
  const { setCurrentStep } = useCreateLogStore((state) => state);
  return (
    <Root>
      <Button
        fullWidth
        variant="contained"
        onClick={() => setCurrentStep("SearchPermit")}
      >
        Log fra tilladelse
      </Button>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setCurrentStep("AddManualLog")}
      >
        Manuel log
      </Button>
    </Root>
  );
};

export default ChooseType;
