import { Box, Typography } from "@mui/material";
import { FC } from "react";

type Props = {
  fullName: string;
  email: string;
};

const RegisterSuccess: FC<Props> = ({ email, fullName }) => (
  <Box>
    <Typography variant="h5" mb={2}>
      Hej {fullName}
    </Typography>
    <Typography variant="body1">
      Vi har sendt en mail til {email} med et link til at bekræfte din konto.
      Tjek dit spamfilter, hvis du ikke kan finde mailen.
    </Typography>
    <Typography variant="caption">Linket er gyldigt i 7 dage.</Typography>
  </Box>
);

export default RegisterSuccess;
