import { FC, useEffect, useState } from "react";
import { MyLogType, useRegisterAdminMutation } from "../../../apollo/types";
import { ME_QUERY } from "../../../routes/root/me";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { BasicInfo, Content, Footer } from "./AuthModal.styles";
import Modal from "../Modal/Modal";
import { GET_PERMITS } from "../../../routes/permits/getPermits";
import PasswordField from "../../PasswordField/PasswordField";
import { GET_MY_LOGS_V2 } from "../../../routes/weaponLogs/queries/getMyLogsV2";

interface Props {
  open: boolean;
  onClose?: () => void;
}

const AuthModal: FC<Props> = ({ open, onClose }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const [registerAdmin] = useRegisterAdminMutation({
    onError: (error) => setError(error.message),
    onCompleted: () => {
      if (onClose) onClose();
    },
  });

  const handleOnClick = async () => {
    const options = {
      variables: {
        input: {
          email,
          firstName,
          lastName,
          password,
          repeatPassword,
        },
      },
      refetchQueries: [
        { query: ME_QUERY },
        { query: GET_PERMITS },
        {
          query: GET_MY_LOGS_V2,
          variables: {
            input: {
              logType: MyLogType.Active,
            },
          },
        },
      ],
    };
    await registerAdmin(options);
  };

  const resetState = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setRepeatPassword("");
    setError("");
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={"Opret Bruger"}
      error={error}
      onDismissError={() => setError("")}
      fullScreen
    >
      <Content>
        <>
          <BasicInfo>
            <TextField
              label="For navn(e)"
              autoFocus
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
            />
            <TextField
              label="Efternavn"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
            />

            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <PasswordField
              id="password-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="password"
            />
            <PasswordField
              id="repeat-password-input"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              label="Gentag password"
            />
          </BasicInfo>
          <Footer>
            <Button variant="contained" onClick={handleOnClick}>
              Opret Bruger
            </Button>
          </Footer>
        </>
      </Content>
    </Modal>
  );
};

export default AuthModal;
