import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import { FC, useState } from "react";
import CreateLogModal from "../../../../components/modals/CreateLogModal/CreateLogModal";
import PrintLogsModal from "../../../../components/modals/PrintLogsModal/PrintLogsModal";
import { MyLogType } from "../../../../apollo/types";

type Props = {
  logsSelected: MyLogType;
  onChangeLogsSelected: (logsSelected: MyLogType) => void;
};

const Header: FC<Props> = ({ logsSelected, onChangeLogsSelected }) => {
  const [showCreateLogModal, setShowCreateLogModal] = useState<boolean>(false);
  const [showPrintLogsModal, setShowPrintLogsModal] = useState<boolean>(false);
  return (
    <>
      <div style={{ display: "flex", gap: "16px" }}>
        <FormControl>
          <InputLabel id="logs-select-label">Valgte logs</InputLabel>
          <Select
            labelId="permits-select-label"
            id="permits-select"
            value={logsSelected}
            label="Valgte logs"
            onChange={(e) => onChangeLogsSelected(e.target.value as MyLogType)}
          >
            <MenuItem value={MyLogType.Active}>Igangværende</MenuItem>
            <MenuItem value={MyLogType.Ended}>Afsluttede</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={() => setShowCreateLogModal(true)}
          startIcon={<AddIcon />}
        >
          Opret log
        </Button>
        <Button
          variant="contained"
          onClick={() => setShowPrintLogsModal(true)}
          startIcon={<PrintIcon />}
        >
          Print logs
        </Button>
      </div>
      <CreateLogModal
        open={showCreateLogModal}
        onClose={() => setShowCreateLogModal(false)}
      />
      <PrintLogsModal
        open={showPrintLogsModal}
        onClose={() => setShowPrintLogsModal(false)}
      />
    </>
  );
};

export default Header;
