import { IconButton, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { FC, useRef, useState } from "react";
import { LogRowFragment, Permit } from "../../../../../apollo/types";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MoreMenu from "../MoreMenu/MoreMenu";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import getOwnerName from "../../../../../utils/getOwnerName";

interface Props {
  row: LogRowFragment;
  setSelectedLogId: (logId: string) => void;
  onClickAddSubLog: () => void;
  onClickSeeDetails: () => void;
  onClickEnd: () => void;
}

const LogTableRow: FC<Props> = ({
  row,
  setSelectedLogId,
  onClickAddSubLog,
  onClickSeeDetails,
  onClickEnd,
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const ref: any = useRef();

  const {
    _id,
    serialNo,
    inputDate,
    permit: { weaponType, caliber, manufacturer, weaponNo, model },
    note,
    givingUser: { fullName: givingUserName },
    receivingUser: { fullName: receivingUserName },
  } = row;

  useOnClickOutside(ref, () => setShowMore(false));
  return (
    <TableRow
      key={_id}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row">
        {serialNo}
      </TableCell>
      <TableCell component="th" scope="row">
        {dayjs(inputDate).utc(true).format("DD/MM/YYYY")}
      </TableCell>
      <TableCell>
        {`${weaponType}/${caliber}/
                    ${manufacturer}-${model}`}
      </TableCell>
      <TableCell>{weaponNo}</TableCell>
      <TableCell>{givingUserName}</TableCell>
      <TableCell>{receivingUserName}</TableCell>
      <TableCell>
        {note || (
          <IconButton
            aria-label="Add note button"
            onClick={() => setSelectedLogId(_id)}
            onMouseDown={() => setSelectedLogId(_id)}
          >
            <NoteAddIcon color="primary" />
          </IconButton>
        )}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        <div ref={ref}>
          <>
            <IconButton
              aria-label="see more sub permit options"
              onClick={() => setShowMore((prevState) => !prevState)}
            >
              <MoreVertIcon />
            </IconButton>
            {showMore && (
              <MoreMenu
                onClickSeeDetails={onClickSeeDetails}
                onClickEnd={onClickEnd}
                onClickAddSubLog={onClickAddSubLog}
              />
            )}
          </>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default LogTableRow;
