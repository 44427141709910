import { FC, useEffect, useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { grey } from "@mui/material/colors";
import Modal from "../modals/Modal/Modal";
import { useGetSignedUrlForGetImageLazyQuery } from "../../apollo/types";

interface SharedProps {
  width?: string;
}

interface PreviewProps extends SharedProps {
  src: string;
}

const Preview = styled("div")<PreviewProps>(({ src, width }) => ({
  position: "relative",
  height: "80px",
  width: `${width || "100%"}`,
  "::before": {
    content: '""',
    backgroundImage: `url("${src}")`,
    backgroundPositionY: "center",
    backgroundSize: "cover",
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    opacity: 0.6,
    borderRadius: "8px",
    border: `2px solid ${grey["400"]}`,
  },
}));

const PdfPreview = styled("embed")({
  position: "relative",
  height: "auto",
  width: "100%",
});

const StyledLink = styled("a")({
  position: "absolute",
  right: "24px",
  top: "8px",
});

const ExpandButton = styled(IconButton)({
  position: "absolute",
  right: "8px",
  top: "8px",
});

interface Props {
  type: string;
  width?: string;
  imageUrl?: string;
  onClickEdit?: () => void;
}

const ImagePreview: FC<Props> = ({ type, width, imageUrl, onClickEdit }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [getImageUrl, { data: image }] = useGetSignedUrlForGetImageLazyQuery();

  useEffect(() => {
    if (imageUrl) {
      getImageUrl({
        variables: { input: { key: imageUrl } },
        fetchPolicy: "network-only",
      });
    }
  }, [expanded, imageUrl]);

  const handleExpandImage = () => {
    setExpanded(true);
  };

  return (
    <>
      {!expanded ? (
        <>
          {type === "application/pdf" ? (
            <Box sx={{ position: "relative" }}>
              <PdfPreview
                width={width}
                src={image?.getImageUrl.url}
                type="application/pdf"
              />
              <StyledLink href={image?.getImageUrl.url} target="_blank">
                <FullscreenIcon sx={{ width: "48px", height: "48px" }} />
              </StyledLink>
            </Box>
          ) : (
            <Preview width={width} src={image?.getImageUrl.url || ""}>
              <ExpandButton
                aria-label="expand image"
                onClick={handleExpandImage}
              >
                <FullscreenIcon />
              </ExpandButton>
            </Preview>
          )}
        </>
      ) : (
        <Modal open={expanded} onClose={() => setExpanded(false)}>
          <img src={image?.getImageUrl.url} />
        </Modal>
      )}
    </>
  );
};

export default ImagePreview;
