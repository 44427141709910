import { FC, useEffect } from "react";
import { IconButton, styled } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { grey } from "@mui/material/colors";
import { useGetSignedUrlForGetImageLazyQuery } from "../../apollo/types";

type PreviewProps = {
  src: string;
};

const Preview = styled("div")<PreviewProps>(({ src }) => ({
  position: "relative",
  height: "80px",
  width: "100%",
  "::before": {
    content: '""',
    backgroundImage: `url("${src}")`,
    backgroundPositionY: "center",
    backgroundSize: "cover",
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    opacity: 0.6,
    borderRadius: "8px",
    border: `2px solid ${grey["400"]}`,
  },
}));

const ExpandButton = styled(IconButton)({
  position: "absolute",
  right: "8px",
  top: "8px",
});

type Props = {
  imageUrl?: string;
  onClick?: () => void;
};

const ImagePreview: FC<Props> = ({ imageUrl, onClick }) => {
  const [getImageUrl, { data: image }] = useGetSignedUrlForGetImageLazyQuery();

  useEffect(() => {
    if (imageUrl) {
      getImageUrl({
        variables: { input: { key: imageUrl } },
        fetchPolicy: "network-only",
      });
    }
  }, [imageUrl]);

  return (
    <Preview src={image?.getImageUrl.url || ""}>
      <ExpandButton aria-label="expand image" onClick={onClick}>
        <FullscreenIcon />
      </ExpandButton>
    </Preview>
  );
};

export default ImagePreview;
