import { Permit } from "../apollo/types";

const getOwnerName = (permit?: Partial<Permit>): string => {
  if (!permit) return "";
  const { owner, user } = permit;
  const { fullName: ownerName } = owner || {};
  const { fullName: userName } = user || {};
  return ownerName || userName || "";
};

export default getOwnerName;
