import { styled } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const ManufacturerModel = styled("div")({
  display: "flex",
  flecDirection: "row",
  gap: 12,
});

export const DatePicker = styled(DesktopDatePicker)({
  flex: 1,
}) as any;
