import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useConfirmUserQuery, UserType } from "../../apollo/types";

const Confirm: FC = () => {
  const { token } = useParams();

  const { data, loading } = useConfirmUserQuery({
    variables: { token: token || "" },
    skip: !token,
  });

  return (
    <Container maxWidth="sm">
      <Box gap={3} display="flex" flexDirection="column">
        {loading ? (
          <Box display="flex" justifyContent="center" mt={8}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography>Hej {data?.confirmUser?.fullName}</Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography>
                {data?.confirmUser?.userType === UserType.Corporate
                  ? "Du kan nu gå igang med at bruge din digitale våbenbog! Tryk på knappen for at komme igang."
                  : data?.confirmUser?.userType === UserType.Private
                  ? "Du kan nu gå igang med at bruge din digitale våbenbog! Tryk på knappen for at komme igang."
                  : ""}
              </Typography>
              <Box>
                <Button onClick={() => location.reload()} variant="contained">
                  Kom i gang!
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Confirm;
