import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  CreateLogInput,
  PermitByWeaponNoFragment,
} from "../../../apollo/types";
import dayjs from "dayjs";

type Step =
  | "AddManualLog"
  | "ChooseType"
  | "CreateLog"
  | "PermitList"
  | "SearchPermit";

type InitialState = {
  error: string;
  currentStep: Step;
  weaponNo: string;
  createLogInput: Partial<CreateLogInput>;
  selectedPermit?: PermitByWeaponNoFragment | undefined;
};
const initialState: InitialState = {
  error: "",
  currentStep: "ChooseType",
  weaponNo: "",
  createLogInput: {
    currentAddress: "",
    inputDate: dayjs(),
    note: "",
  },
  selectedPermit: undefined,
};

type State = {
  error: string;
  setError: (error: string) => void;
  currentStep: Step;
  setCurrentStep: (step: Step) => void;
  weaponNo: string;
  setWeaponNo: (weaponNo: string) => void;
  selectedPermit?: PermitByWeaponNoFragment;
  setSelectedPermit: (permit: PermitByWeaponNoFragment | undefined) => void;
  createLogInput?: Partial<CreateLogInput>;
  setCreateLogInput: (input: Partial<CreateLogInput>) => void;
  resetStore: () => void;
};

const useCreateLogStore = create<State>()(
  devtools((set) => ({
    error: initialState.error,
    setError: (error) => set({ error }),
    currentStep: initialState.currentStep as Step,
    setCurrentStep: (step) => set({ currentStep: step }),
    weaponNo: initialState.weaponNo,
    setWeaponNo: (weaponNo) => set({ weaponNo }),
    createLogInput: initialState.createLogInput,
    setCreateLogInput: (input) => set({ createLogInput: input }),
    selectedPermit: initialState.selectedPermit,
    setSelectedPermit: (permit) => set({ selectedPermit: permit }),
    resetStore: () => set(initialState as State),
  }))
);

export default useCreateLogStore;
