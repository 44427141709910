import { useApolloClient } from "@apollo/client";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import { FC } from "react";
import { useLogoutMutation } from "../../apollo/types";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";

const UserMenu: FC = () => {
  const navigate = useNavigate();

  const [logout] = useLogoutMutation({ onCompleted: () => navigate("/") });

  const client = useApolloClient();

  const handleLogout = async () => {
    await logout();
    client.resetStore();
  };

  return (
    <Paper
      elevation={4}
      sx={{ width: 240, position: "absolute", top: 32, right: 0, zIndex: 10 }}
    >
      <MenuList>
        <MenuItem onClick={() => navigate("/settings")}>
          <ListItemIcon>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Bruger indstillinger</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Log ud</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
};

export default UserMenu;
