import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";

interface Props {
  children?: ReactNode;
}

const DetailsCard: FC<Props> = ({ children }) => (
  <Box sx={{ background: grey["200"], borderRadius: 1, padding: 1 }}>
    {children}
  </Box>
);

export default DetailsCard;
