import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import { FC } from "react";

type Props = {
  imageName: string;
  onClickRemove: () => void;
};

const ImageUploaded: FC<Props> = ({ imageName, onClickRemove }) => (
  <div style={{ display: "flex", gap: 1, alignItems: "center" }}>
    <CheckIcon color="success" />
    <Typography>{imageName}</Typography>
    <IconButton
      aria-label="Remove image button"
      onClick={onClickRemove}
      onMouseDown={onClickRemove}
    >
      <CloseIcon color="error" />
    </IconButton>
  </div>
);

export default ImageUploaded;
