import { Box, Button, styled, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import dayjs from "dayjs";
import { FC, useMemo, useState } from "react";
import {
  Permit,
  useDeletePermitMutation,
  useGetPermitDetailsQuery,
} from "../../../apollo/types";
import { Status } from "../../../routes/permits/components/PermitTable/PermitTable.styles";
import { Dot } from "../../../styles/Shared.styles";
import DetailsCard from "../../DetailsCard/DetailsCard";
import PermitDetailsCard from "../../PermitDetailsCard/PermitDetailsCard";
import Modal from "../Modal/Modal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { GET_PERMITS } from "../../../routes/permits/getPermits";
import ValidPeriodDetailsCard from "../../ValidPeriodDetailsCard/ValidPeriodDetailsCard";

interface Props {
  open: boolean;
  onClose: () => void;
  permitId: string;
}

const Row = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
});

const SeePermitDetailsModal: FC<Props> = ({ open, onClose, permitId }) => {
  const [error, setError] = useState<string>("");
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const { data } = useGetPermitDetailsQuery({ variables: { id: permitId } });
  const mostRecentLog = useMemo(
    () => data?.getPermitById?.logs?.find((log) => !log.isEnded),
    [data]
  );

  const [deletePermit, { loading }] = useDeletePermitMutation({
    variables: { permitId },
    onCompleted: () => {
      setShowConfirmDialog(false);
      onClose();
    },
    refetchQueries: [{ query: GET_PERMITS }],
  });

  const handleOnClose = () => {
    onClose();
  };

  return (
    <>
      <Modal
        error={error}
        onDismissError={() => setError("")}
        open={open}
        onClose={handleOnClose}
        title={`Detaljer journalnr: ${data?.getPermitById.journalNo}`}
        width={680}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <PermitDetailsCard permit={data?.getPermitById as Permit} />
          <ValidPeriodDetailsCard
            validFrom={data?.getPermitById.validFrom || ""}
            validTo={data?.getPermitById.validTo || ""}
            journalNo={data?.getPermitById.journalNo || ""}
          />
          <DetailsCard>
            <Typography variant="body1" mb={1}>
              Hjemmeadresse:
            </Typography>
            <Typography variant="body2">
              {data?.getPermitById.storageAddress}
            </Typography>
          </DetailsCard>
          <DetailsCard>
            <Typography variant="body1" mb={1}>
              Status:
            </Typography>
            {!data?.getPermitById.isHome ? (
              <>
                {mostRecentLog && (
                  <>
                    <Row>
                      <Status>
                        <Dot size={12} color={red[400]} />{" "}
                        <Typography variant="body2">Ude</Typography>
                      </Status>
                      <Typography>Nuværende adresse:</Typography>
                      <Typography variant="body2">
                        {mostRecentLog.currentAddress}
                      </Typography>
                      <Typography variant="body2">
                        {mostRecentLog.receivingUser.fullName}
                      </Typography>
                      <Typography variant="body2">
                        {dayjs(mostRecentLog.inputDate)
                          .utc(true)
                          .format("DD/MM/YYYY")}
                      </Typography>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <Status>
                <Dot size={12} color={green[400]} />{" "}
                <Typography variant="body2">Hjemme</Typography>
              </Status>
            )}
          </DetailsCard>
        </Box>
        <Button
          variant="outlined"
          color="error"
          startIcon={<DeleteForeverIcon />}
          onClick={() => setShowConfirmDialog(true)}
        >
          Slet tilladelse
        </Button>
      </Modal>
      <ConfirmDialog
        title="Slet tilladelse?"
        text={`Er du sikker på at du vil slette tilladelse med journalNr: ${data?.getPermitById.journalNo} ?`}
        agreeButton={{
          label: "Slet",
          color: "error",
          variant: "outlined",
          onClick: deletePermit,
          disabled: loading,
        }}
        disagreeButton={{
          label: "Fortryd",
          color: "primary",
          variant: "outlined",
          onClick: () => setShowConfirmDialog(false),
          disabled: loading,
        }}
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      />
    </>
  );
};

export default SeePermitDetailsModal;
