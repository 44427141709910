import { FC } from "react";
import Menu from "../../../../../components/Menu/Menu";
import { Props as Item } from "../../../../../components/Menu/MenuItem";
import PostAddIcon from "@mui/icons-material/PostAdd";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material";

const Root = styled("div")({
  position: "absolute",
  zIndex: 100,
  right: 24,
});

interface Props {
  onClickSeeDetails: () => void;
  onClickAddSubPermit?: () => void;
  onClickEdit: () => void;
}

const MoreMenu: FC<Props> = ({
  onClickSeeDetails,
  onClickAddSubPermit,
  onClickEdit,
}) => {
  const items: Item[] = [
    {
      text: "Se detaljer",
      icon: <InfoIcon />,
      onClick: onClickSeeDetails,
    },
    {
      text: `Rediger ${!!onClickAddSubPermit ? "" : "del-"}tilladelse`,
      icon: <EditIcon />,
      onClick: onClickEdit,
    },
    ...(!!onClickAddSubPermit
      ? [
          {
            text: "Opret del-tilladelse",
            icon: <PostAddIcon />,
            onClick: onClickAddSubPermit,
          },
        ]
      : []),
  ];

  return (
    <Root>
      <Menu items={items} width={210} maxWidth="100%" />
    </Root>
  );
};

export default MoreMenu;
