import { gql } from "@apollo/client";

const PermitRow = gql`
  fragment PermitRow on Permit {
    _id
    weaponType
    caliber
    isSubPermit
    isHome
    weaponNo
    manufacturer
    model
    user {
      _id
    }
    owner {
      _id
    }
    isHome
    subPermits {
      _id
      weaponType
      caliber
      isSubPermit
      weaponPart
      isHome
      weaponNo
      manufacturer
      model
      user {
        _id
      }
      owner {
        _id
      }
    }
  }
`;

export const GET_PERMITS = gql`
  ${PermitRow}
  query getPermits {
    getPermits {
      ...PermitRow
    }
  }
`;
