import { gql } from "@apollo/client";

export const ME_QUERY = gql`
  query me {
    me {
      _id
      firstName
      lastName
      email
      fullName
      userType
      admin
      confirmed
      address
      weeklyMail
      getWeeklyMail
    }
  }
`;
