import { Box, styled } from "@mui/material";

export const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

export const InnerCard = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "24px",
});

export const CardSection = styled(Box)({
  display: "flex",
  gap: "6px",
  alignItems: "baseline",
});
