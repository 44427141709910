// TODO: Refactor this component when time!!
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  styled,
} from "@mui/material";
import { FC, useMemo, useState } from "react";
import TextArea from "../../../../TextArea/TextArea";
import { DatePicker } from "../../../Modal/Shared.styles";
import dayjs, { Dayjs } from "dayjs";
import ImageUploadSection from "../../../../ImageUploadSection/ImageUploadSection";
import { v4 as uuidv4 } from "uuid";
import useCreateLogStore from "../../createLogStore";
import {
  CorporateClientFragment,
  ManualLogType,
  MyLogType,
  useCreateManualLogMutation,
  useMeQuery,
} from "../../../../../apollo/types";
import LoadingButton from "../../../../LoadingButton/LoadingButton";
import CorporateClientSelect from "./CorporateClientSelect/CorporateClientSelect";
import { GET_MY_LOGS_V2 } from "../../../../../routes/weaponLogs/queries/getMyLogsV2";
import ValidFromTo from "../../../../ValidFromTo/ValidFromTo";
import FuzzySearchSelect from "../../../../FuzzySearchSelect/FuzzySearchSelect";

const TextAreaWrapper = styled("div")({
  display: "flex",
});

type Props = {
  onClose: () => void;
};

const initialState = {
  logType: ManualLogType.Standard,
  name: "",
  contactFirstName: "",
  contactLastName: "",
  email: "",
  fromAddress: "",
  date: dayjs(),
  address: "",
  weaponType: "",
  manufacturer: "",
  model: "",
  weaponNo: "",
  caliber: "",
  journalNo: "",
  note: "",
  imageUrl: "",
  imageType: "",
  loadingImage: false,
  userId: "",
  validFrom: dayjs(),
  validTo: dayjs(),
  corporateValidFrom: dayjs(),
  corporateValidTo: dayjs(),
};

const AddManualLog: FC<Props> = ({ onClose }) => {
  const { setError } = useCreateLogStore((state) => state);
  const [logType, setLogType] = useState<ManualLogType>(initialState.logType);
  const [name, setName] = useState<string>(initialState.name);
  const [contactFirstName, setContactFirstName] = useState<string>(
    initialState.contactFirstName
  );
  const [contactLastName, setContactLastName] = useState<string>(
    initialState.contactLastName
  );
  const [email, setEmail] = useState<string>(initialState.email);
  const [fromAddress, setFromAddress] = useState<string>(
    initialState.fromAddress
  );
  const [date, setDate] = useState<Dayjs | null>(initialState.date);
  const [address, setAddress] = useState<string>(initialState.address);
  const [weaponType, setWeaponType] = useState<string>(initialState.weaponType);
  const [manufacturer, setManufacturer] = useState<string>(
    initialState.manufacturer
  );
  const [model, setModel] = useState<string>(initialState.model);
  const [weaponNo, setWeaponNo] = useState<string>(initialState.weaponNo);
  const [caliber, setCaliber] = useState<string>(initialState.caliber);
  const [journalNo, setJournalNo] = useState<string>(initialState.journalNo);
  const [note, setNote] = useState<string>(initialState.note);
  const [imageUrl, setImageUrl] = useState<string>(initialState.imageUrl);
  const [imageType, setImageType] = useState<string>(initialState.imageType);
  const [loadingImage, setLoadingImage] = useState<boolean>(
    initialState.loadingImage
  );
  const [validFrom, setValidFrom] = useState<Dayjs | null>(
    initialState.validFrom
  );
  const [validTo, setValidTo] = useState<Dayjs | null>(initialState.validTo);
  const [userId, setUserId] = useState<string>("");
  const [corporateValidFrom, setCorporateValidFrom] = useState<Dayjs | null>(
    initialState.corporateValidFrom
  );
  const [corporateValidTo, setCorporateValidTo] = useState<Dayjs | null>(
    initialState.corporateValidTo
  );

  const { data: me, refetch } = useMeQuery({
    onCompleted: (data) => {
      if (data.me?.address) {
        setAddress(data.me.address);
      }
    },
  });

  const resetOwnState = (logType?: ManualLogType) => {
    setLogType(logType || initialState.logType);
    setName(initialState.name);
    setContactFirstName(initialState.contactFirstName);
    setContactLastName(initialState.contactLastName);
    setEmail(initialState.email);
    setFromAddress(initialState.fromAddress);
    setDate(initialState.date);
    setAddress(initialState.address);
    setWeaponType(initialState.weaponType);
    setManufacturer(initialState.manufacturer);
    setModel(initialState.model);
    setWeaponNo(initialState.weaponNo);
    setCaliber(initialState.caliber);
    setJournalNo(initialState.journalNo);
    setNote(initialState.note);
    setImageUrl(initialState.imageUrl);
    setImageType(initialState.imageType);
    setLoadingImage(initialState.loadingImage);
    setUserId(initialState.userId);
    setCorporateValidFrom(initialState.corporateValidFrom);
    setCorporateValidTo(initialState.corporateValidTo);
  };

  const [createLog, { loading: createLoading }] = useCreateManualLogMutation({
    onError: (err) => setError(err.message),
    onCompleted: (data) => {
      if (data) {
        resetOwnState();
        onClose();
      }
    },
    refetchQueries: [
      {
        query: GET_MY_LOGS_V2,
        variables: {
          input: {
            logType: MyLogType.Active,
          },
        },
      },
    ],
  });

  const loading = useMemo(
    () => createLoading || loadingImage,
    [createLoading, loadingImage]
  );

  const handleImageUploaded = (key: string, contentType: string) => {
    setImageUrl(key);
    setImageType(contentType);
  };

  const handleImageRemove = () => {
    setImageUrl("");
    setImageType("");
  };

  const handleCreateLog = () => {
    createLog({
      variables: {
        input: {
          caliber,
          corporatePermitImageType: imageType,
          corporatePermitImageUrl: imageUrl,
          corporateJournalNo: journalNo,
          corporatePermitValidFrom: corporateValidFrom,
          corporatePermitValidTo: corporateValidTo,
          currentAddress: address,
          fromAddress,
          firstName: contactFirstName,
          lastName: contactLastName,
          manufacturer,
          model,
          name,
          note,
          inputDate: date,
          journalNo,
          logType,
          receivingUserId: me?.me?._id || "",
          validFrom,
          validTo,
          weaponNo,
          weaponType,
          email,
          userId,
        },
      },
    });
  };

  const getIsDisabled = () => {
    const commonFields = [
      contactFirstName,
      contactLastName,
      date,
      address,
      weaponType,
      manufacturer,
      model,
      weaponNo,
      caliber,
      journalNo,
      validFrom,
      validTo,
      note,
      email,
      fromAddress,
    ];

    let fieldsToCheck: any[] = [];

    if (logType === ManualLogType.Business) {
      fieldsToCheck = [name, ...commonFields];

      if (!userId) {
        fieldsToCheck.push(imageType, imageUrl);
      }
    } else if (logType === ManualLogType.Standard) {
      fieldsToCheck = [imageType, imageUrl, ...commonFields];
    }

    return fieldsToCheck.some((field) => !field);
  };

  const handleOnChangeRadio = async (value: ManualLogType) => {
    resetOwnState();
    setLogType(value);
    // A bit of a hacky solution below, but it works for now
    const { data } = await refetch();
    if (data.me?.address) {
      setAddress(data.me.address);
    }
  };

  const handleSelectCorporateClient = (client: CorporateClientFragment) => {
    setUserId(client._id);
    setName(client.corporateName || "");
    setContactFirstName(client.firstName);
    setContactLastName(client.lastName);
    setEmail(client.email || "");
    setJournalNo(client.corporateJournalNo || "");
    setImageType(client.corporatePermitImageType || "");
    setImageUrl(client.corporatePermitImageUrl || "");
    setCorporateValidFrom(client.corporatePermitValidFrom || null);
    setCorporateValidTo(client.corporatePermitValidTo || null);
    setFromAddress(client.address || "");
  };

  return (
    <>
      <FormControl>
        <FormLabel id="log-type-radio-buttons-group-label">Kundetype</FormLabel>
        <RadioGroup
          onChange={(e) => handleOnChangeRadio(e.target.value as ManualLogType)}
          aria-labelledby="log-type-radio-buttons-group-label"
          defaultValue={ManualLogType.Standard}
          name="log-type-radio-buttons-group"
        >
          <FormControlLabel
            value={ManualLogType.Business}
            control={<Radio color="secondary" />}
            label="Erhvervskunde"
          />
          <FormControlLabel
            value={ManualLogType.Standard}
            control={<Radio color="secondary" />}
            label="Standard"
          />
        </RadioGroup>
      </FormControl>
      <Divider />
      {logType === ManualLogType.Business && (
        <CorporateClientSelect
          onSelectCorporateClient={handleSelectCorporateClient}
          onClickAddNewCorporateClient={() =>
            resetOwnState(ManualLogType.Business)
          }
        />
      )}
      {/* // TODO: add serial no here when it's ready 
      <>{serialNo}</>
      */}
      {logType === ManualLogType.Business && (
        <TextField
          label="Virksomhedens navn"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          disabled={loading || !!userId}
        />
      )}
      <>
        <TextField
          label={
            logType === ManualLogType.Business
              ? "Kontaktperson (fornavn)"
              : "Fornavn"
          }
          value={contactFirstName}
          onChange={(e) => setContactFirstName(e.target.value)}
          fullWidth
          disabled={loading || !!userId}
        />
        <TextField
          label={
            logType === ManualLogType.Business
              ? "Kontaktperson (efternavn)"
              : "Efternavn"
          }
          value={contactLastName}
          onChange={(e) => setContactLastName(e.target.value)}
          fullWidth
          disabled={loading || !!userId}
        />
      </>
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        disabled={loading || !!userId}
      />
      <TextField
        label={`${
          logType === ManualLogType.Business
            ? "Kundes addresse"
            : "Addresse på tilladelse"
        }`}
        value={fromAddress}
        onChange={(e) => setFromAddress(e.target.value)}
        fullWidth
        disabled={loading || !!userId}
      />
      <TextField
        label="Journal nr."
        value={journalNo}
        onChange={(e) => setJournalNo(e.target.value)}
        fullWidth
        disabled={loading || !!userId}
      />
      {logType === ManualLogType.Business && (
        <ValidFromTo
          disabled={loading || !!userId}
          onChange={(value) => {
            setCorporateValidFrom(value.validFrom);
            setCorporateValidTo(value.validTo);
          }}
          defaultValidFrom={corporateValidFrom}
          defaultValidTo={corporateValidTo}
        />
      )}
      <ImageUploadSection
        uploadId={uuidv4()}
        hasImage={!!imageUrl}
        onImageError={(error) => setError(error)}
        onImageRemove={handleImageRemove}
        onImageUploading={setLoadingImage}
        onImageUploaded={handleImageUploaded}
        disabled={loading || !!userId}
      />
      <DatePicker
        label="Dato"
        inputFormat="DD/MM/YYYY"
        value={date}
        onChange={(value: Dayjs) => setDate(value)}
        renderInput={(params: any) => <TextField {...params} />}
        disabled={loading}
      />
      <TextField
        label="Til addresse"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        fullWidth
        disabled={loading}
      />
      <TextField
        label="Våbenart/type"
        value={weaponType}
        onChange={(e) => setWeaponType(e.target.value)}
        fullWidth
        disabled={loading}
      />
      <FuzzySearchSelect
        label="Fabrikat"
        selectType="manufacturers"
        disabled={loading}
        fullWidth
        onSelectResult={(value) => setManufacturer(value)}
      />
      <TextField
        label="Model"
        value={model}
        onChange={(e) => setModel(e.target.value)}
        fullWidth
        disabled={loading}
      />
      <TextField
        label="Våben nr."
        value={weaponNo}
        onChange={(e) => setWeaponNo(e.target.value)}
        fullWidth
        disabled={loading}
      />
      <FuzzySearchSelect
        label="Kaliber"
        selectType="calibers"
        disabled={loading}
        fullWidth
        onSelectResult={(value) => setCaliber(value)}
      />
      {logType === ManualLogType.Standard && (
        <ValidFromTo
          disabled={loading}
          onChange={({ validFrom, validTo }) => {
            setValidFrom(validFrom);
            setValidTo(validTo);
          }}
          defaultValidFrom={validFrom}
          defaultValidTo={validTo}
        />
      )}
      <TextAreaWrapper>
        <TextArea
          minRows={3}
          placeholder="Note"
          value={note || ""}
          onChange={(e) => setNote(e.target.value)}
          disabled={loading}
        />
      </TextAreaWrapper>
      <Divider />
      <LoadingButton
        loading={loading}
        onClick={() => handleCreateLog()}
        label="Opret log"
        disabled={getIsDisabled()}
      />
    </>
  );
};
export default AddManualLog;
