import { FC, ReactElement } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";

interface Props {
  mainCTA: ReactElement;
  leftCol?: ReactElement;
  mainContent: ReactElement;
}

const Page: FC<Props> = ({ mainCTA, leftCol, mainContent }) => {
  return (
    <Grid2
      container
      spacing={2}
      flex={1}
      maxWidth={1440}
      margin={"0 auto"}
      padding={1}
    >
      {leftCol && <Grid2 xs={2} />}
      <Grid2
        xs={leftCol ? 10 : 12}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <div>{mainCTA}</div>
      </Grid2>
      {leftCol && (
        <Grid2 xs={2}>
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            {leftCol}
          </div>
        </Grid2>
      )}
      <Grid2
        xs={leftCol ? 10 : 12}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        {mainContent}
      </Grid2>
    </Grid2>
  );
};

export default Page;
