import { FC, ReactElement } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  IconButton,
  Skeleton,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

const StyledSkeleton = styled(Skeleton)(() => ({
  borderRadius: "4px",
}));

interface Props extends DialogProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  width?: number;
  error?: string;
  onDismissError?: () => void;
  header?: ReactElement;
  goBack?: () => void;
  loading?: boolean;
}

const Modal: FC<Props> = ({
  open,
  onClose,
  title,
  children,
  width = 580,
  error,
  header,
  onDismissError,
  goBack,
  loading = false,
  ...props
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      {...props}
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      sx={{
        "& .MuiDialog-paper": {
          width,
          padding: "24px 16px",
          gap: "24px",
        },
      }}
    >
      {(!!title || !!goBack) &&
        (loading ? (
          <DialogTitle sx={{ padding: "0" }}>{title}</DialogTitle>
        ) : (
          <DialogTitle
            sx={{
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {!!goBack && (
              <IconButton aria-label="go back" onClick={goBack}>
                <ArrowBackIcon />
              </IconButton>
            )}
            {title}
            {onClose && (
              <IconButton aria-label="close modal" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
        ))}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            paddingBottom: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <StyledSkeleton
              animation="wave"
              variant="rectangular"
              width={"70%"}
              height={40}
            />
            <StyledSkeleton
              animation="wave"
              variant="rectangular"
              width={"30%"}
              height={40}
            />
          </Box>
          <div />
          <div />
          <StyledSkeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={40}
          />
          <StyledSkeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={40}
          />
          <div />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <StyledSkeleton
              animation="wave"
              variant="rectangular"
              width={"40%"}
              height={40}
            />
            <StyledSkeleton
              animation="wave"
              variant="rectangular"
              width={"60%"}
              height={40}
            />
          </Box>
        </Box>
      ) : (
        !!error && (
          <Alert sx={{ zIndex: 10 }} severity="error" onClose={onDismissError}>
            {error}
          </Alert>
        )
      )}
      {children}
    </Dialog>
  );
};

export default Modal;
