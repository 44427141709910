import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";

type ButtonProps = {
  onClick: () => void;
  label: string;
  variant?: "text" | "outlined" | "contained";
  color?: "primary" | "secondary" | "success" | "error" | "warning";
  disabled?: boolean;
};
type Props = {
  title: string;
  text: string;
  agreeButton: ButtonProps;
  disagreeButton: ButtonProps;
  open: boolean;
  onClose: () => void;
};

const ConfirmDialog: FC<Props> = ({
  title,
  text,
  open,
  onClose,
  agreeButton,
  disagreeButton,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-title"
      aria-describedby="confirm-description"
    >
      <DialogTitle id="confirm-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={disagreeButton.variant || "text"}
          color={disagreeButton.color || "primary"}
          onClick={disagreeButton.onClick}
        >
          {disagreeButton.label}
        </Button>
        <Button
          variant={agreeButton.variant || "text"}
          color={agreeButton.color || "primary"}
          onClick={agreeButton.onClick}
        >
          {agreeButton.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
