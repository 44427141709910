import { Button, TextareaAutosize, TextField, Typography } from "@mui/material";
import { DatePicker } from "../Modal/Shared.styles";
import dayjs, { Dayjs } from "dayjs";
import { FC, useState } from "react";
import {
  LogRowFragment,
  MyLogType,
  useCreateSubLogMutation,
  useMeQuery,
} from "../../../apollo/types";
import Modal from "../Modal/Modal";
import { grey } from "@mui/material/colors";
import { GET_MY_LOGS_V2 } from "../../../routes/weaponLogs/queries/getMyLogsV2";

interface Props {
  open: boolean;
  onClose: () => void;
  log?: LogRowFragment;
}

const MoveLogModal: FC<Props> = ({ open, onClose, log }) => {
  const [error, setError] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [inputDate, setInputDate] = useState<Dayjs | null>(dayjs());
  const [currentAddress, setCurrentAddress] = useState<string>("");

  const { data: me } = useMeQuery();

  const [move] = useCreateSubLogMutation({
    onError: (err) => setError(err.message),
    onCompleted: (data) => {
      if (data) {
        onClose();
      }
    },
    refetchQueries: [
      {
        query: GET_MY_LOGS_V2,
        variables: {
          input: {
            logType: MyLogType.Active,
          },
        },
      },
    ],
  });

  const handleOnClose = () => {
    onClose();
  };

  const handleOnClickFooter = () => {
    move({
      variables: {
        input: {
          currentAddress,
          inputDate,
          receivingUserId: me?.me?._id || "",
          note,
          rootLogId: log?._id || "",
        },
      },
    });
  };

  return (
    <Modal
      error={error}
      onDismissError={() => setError("")}
      open={open}
      onClose={handleOnClose}
      title={`Flyt ${log?.permit.weaponPart || log?.permit.weaponType}`}
      width={680}
    >
      <Typography variant="h6">Journal nr: {log?.permit.journalNo}</Typography>
      <DatePicker
        label="Dato"
        inputFormat="DD/MM/YYYY"
        value={inputDate}
        onChange={(value: Dayjs) => setInputDate(value)}
        renderInput={(params: any) => <TextField {...params} />}
      />
      <TextField
        label="Til adresse"
        value={currentAddress}
        onChange={(e) => setCurrentAddress(e.target.value)}
        fullWidth
      />
      <TextareaAutosize
        minRows={3}
        placeholder="Note"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        style={{
          borderColor: grey[400],
          borderRadius: 4,
          padding: 8,
          fontFamily: "Roboto",
          fontSize: "16px",
        }}
      />
      <Button
        disabled={!log || !me?.me || !currentAddress}
        variant="contained"
        onClick={handleOnClickFooter}
      >
        Flyt
      </Button>
    </Modal>
  );
};

export default MoveLogModal;
