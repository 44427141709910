import { Button, CircularProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import styled from "@emotion/styled";
import useUploadImage from "../../hooks/useUploadImage";
import ImageUploaded from "./ImageUploaded";

const Root = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginRight: "0.5rem",
});

type Props = {
  uploadId: string;
  onImageError: (err: string) => void;
  onImageUploaded: (key: string, contentType: string) => void;
  onImageUploading: (loading: boolean) => void;
  onImageRemove: () => void;
  hasImage: boolean;
  disabled?: boolean;
};

const ImageUploadSection: FC<Props> = ({
  uploadId,
  onImageError,
  onImageUploaded,
  onImageUploading,
  onImageRemove,
  hasImage,
  disabled,
}) => {
  const [image, setImage] = useState<File | null>(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const { handleUploadImage, loading } = useUploadImage(
    (key, contentType) => {
      onImageUploaded(key, contentType);
    },
    (err) => onImageError(err)
  );

  useEffect(() => {
    if (image) {
      handleUploadImage(uploadId, image);
    }
  }, [image]);

  useEffect(() => {
    onImageUploading(loading);
  }, [loading]);

  const handleRemoveImage = () => {
    setImage(null);
    onImageRemove();
    setFileInputKey(Date.now());
  };

  return (
    <Root>
      <Button
        disabled={loading || disabled}
        variant="contained"
        component="label"
        endIcon={<InsertDriveFileOutlinedIcon />}
      >
        Upload Tilladelse
        <input
          key={fileInputKey}
          type="file"
          hidden
          accept="image/*, .pdf, .heic, .heif, image/heic, image/heif"
          onChange={(e) => {
            setImage(e.target.files && e.target.files[0]);
          }}
        />
      </Button>
      {loading && <CircularProgress color={"info"} size={20} />}
      {!loading && !!hasImage && !disabled && (
        <ImageUploaded
          imageName={image?.name || ""}
          onClickRemove={handleRemoveImage}
        />
      )}
    </Root>
  );
};

export default ImageUploadSection;
