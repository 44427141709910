import { FC, Fragment, useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LogRowFragment, Permit } from "../../../../apollo/types";
import AddNoteModal from "../../../../components/modals/AddNoteModal/AddNoteModal";

import LogTableRow from "./LogTableRow/LogTableRow";
import MoveLogModal from "../../../../components/modals/MoveLogModal/MoveLogModal";
import SubLogTableRow from "./SubLogTableRow/SubLogTableRow";
import EndLogModal from "../../../../components/modals/EndLogModal/EndLogModal";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SeeLogDetailsModal from "../../../../components/modals/SeeLogDetailsModal/SeeLogDetailsModal";
import getOwnerName from "../../../../utils/getOwnerName";

interface Props {
  rows: LogRowFragment[];
  onClickRow: (id: string) => void;
}

const LogTable: FC<Props> = ({ rows, onClickRow }) => {
  const [selectedLogId, setSelectedLogId] = useState<string>("");
  const [moveLog, setMoveLog] = useState<LogRowFragment | undefined>(undefined);
  const [endLog, setEndLog] = useState<LogRowFragment | undefined>(undefined);
  const [query, setQuery] = useState<string>("");
  const [logDetailsId, setLogDetailsId] = useState<string>("");

  const filteredRows = useMemo(
    () =>
      rows.filter(
        (row) =>
          row.permit.weaponNo.toLowerCase().includes(query.toLowerCase()) ||
          row.permit.journalNo.toLowerCase().includes(query.toLowerCase())
      ),
    [query, rows]
  );

  return (
    <>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-journal-no">
          Søg i logs
        </InputLabel>
        <OutlinedInput
          id="input-journal-no"
          autoFocus
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
          type="text"
          placeholder="Søg på våbennr. eller journalnr."
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          label="Journal nr."
        />
      </FormControl>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Løbenr</TableCell>
              <TableCell>Dato</TableCell>
              <TableCell>Våben</TableCell>
              <TableCell>Våbennr</TableCell>
              <TableCell>Fra</TableCell>
              <TableCell>Til</TableCell>
              <TableCell>Note</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows?.length > 0 &&
              filteredRows.map((row, index) => {
                if (!row.isSubLog)
                  return (
                    <Fragment key={row._id}>
                      <LogTableRow
                        key={`${row._id}${index}`}
                        onClickAddSubLog={() => setMoveLog(row)}
                        row={row}
                        setSelectedLogId={(logId) => setSelectedLogId(logId)}
                        onClickEnd={() => setEndLog(row)}
                        onClickSeeDetails={() => setLogDetailsId(row._id)}
                      />
                      {!!row.subLogs?.length &&
                        row.subLogs.map((subLog, index) => (
                          <SubLogTableRow
                            key={`${subLog._id}-${index}`}
                            row={subLog}
                            setSelectedLogId={() =>
                              setSelectedLogId(subLog._id)
                            }
                            onClickSeeDetails={() =>
                              setLogDetailsId(subLog._id)
                            }
                          />
                        ))}
                    </Fragment>
                  );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <AddNoteModal
        open={!!selectedLogId}
        onClose={() => setSelectedLogId("")}
        logId={selectedLogId}
      />
      <MoveLogModal
        open={!!moveLog}
        onClose={() => setMoveLog(undefined)}
        log={moveLog}
      />
      {!!endLog && (
        <EndLogModal
          open={!!endLog}
          ownerName={getOwnerName((endLog?.permit as Permit) || undefined)}
          logId={endLog._id}
          onClose={() => setEndLog(undefined)}
        />
      )}
      <SeeLogDetailsModal
        open={!!logDetailsId}
        onClose={() => setLogDetailsId("")}
        id={logDetailsId}
      />
    </>
  );
};

export default LogTable;
