import styled from "@emotion/styled";
import { Box, Button, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useMemo, useState } from "react";
import ImagePreviewThumb from "../../ImagePreview/ImagePreviewThumb";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ExpandedImageModal from "./ExpandedImageModal";
import {
  useEditAndUpdatePermitMutation,
  useGetPermitByIdQuery,
} from "../../../apollo/types";
import useUploadImage from "../../../hooks/useUploadImage";
import { GET_PERMITS } from "../../../routes/permits/getPermits";
import { GET_PERMIT_BY_ID } from "./getPermitById";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const AddImageButton = styled(IconButton)({
  position: "relative",
  height: "80px",
  width: "100%",
  background: grey["300"],
  border: `2px solid ${grey["400"]}`,
  opacity: 0.6,
  borderRadius: "8px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ":hover": {
    opacity: 1,
  },
});

type Props = {
  permitId: string;
  onError: (error: string) => void;
  onClickBack: () => void;
};

const Images: FC<Props> = ({ permitId, onError, onClickBack }) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const { data, loading: getPermitLoading } = useGetPermitByIdQuery({
    variables: { id: permitId },
    onError: () => onError("Der skete en fejl ved hentning af tilladelse"),
  });
  const { getPermitById } = data || {};
  const { images } = getPermitById || {};
  const [fetchedImages, setFetchedImages] = useState<string[]>(images || []);
  const [expandedImage, setExpandedImage] = useState<string>("");

  const { handleUploadImage, loading: imageLoading } = useUploadImage(
    async (key) => {
      await edit({
        variables: {
          input: { permitId, images: [...(images || []), key] },
        },
      });
      setFetchedImages([...(images || []), key]);
    },
    (err) => onError(err)
  );

  const [edit] = useEditAndUpdatePermitMutation({
    onCompleted: async () => {
      setUploading(false);
    },
    onError: (error) => {
      setUploading(false);
      onError(error.message);
    },
    refetchQueries: [
      { query: GET_PERMITS },
      { query: GET_PERMIT_BY_ID, variables: { id: permitId } },
    ],
  });

  const loading = useMemo(
    () => uploading || getPermitLoading || imageLoading,
    [uploading, getPermitLoading, imageLoading]
  );
  return (
    <>
      <Button
        color="primary"
        variant="text"
        startIcon={<ArrowBackIosIcon />}
        onClick={onClickBack}
      >
        Tilbage
      </Button>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: "1fr 1fr",
          "@media (min-width: 340px)": {
            gridTemplateColumns: "1fr 1fr 1fr",
          },
          "@media (min-width: 500px)": {
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          },
        }}
      >
        <AddImageButton
          // @ts-ignore
          component="label"
          color="primary"
          disabled={loading}
        >
          <AddPhotoAlternateIcon sx={{ width: "36px", height: "36px" }} />
          <input
            type="file"
            hidden
            accept="image/*, .heic, .heif, image/heic, image/heif"
            onChange={(e) =>
              handleUploadImage(permitId, e.target.files && e.target.files[0])
            }
            disabled={loading}
          />
        </AddImageButton>
        {fetchedImages?.map((image, index) => (
          <ImagePreviewThumb
            key={`${image}-${index}`}
            imageUrl={image}
            onClick={() => setExpandedImage(image)}
          />
        ))}
      </Box>
      {!!expandedImage && (
        <ExpandedImageModal
          defaultExpandedImage={expandedImage}
          images={images || []}
          onClose={() => setExpandedImage("")}
          permitId={permitId}
        />
      )}
    </>
  );
};

export default Images;
