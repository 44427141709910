import { FC, useMemo, useState } from "react";
import ImageUploadSection from "../../../ImageUploadSection/ImageUploadSection";
import { Button, TextField } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ManufacturerModel } from "../../Modal/Shared.styles";
import LoadingButton from "../../../LoadingButton/LoadingButton";
import {
  useEditAndUpdatePermitMutation,
  useGetPermitByIdQuery,
} from "../../../../apollo/types";
import dayjs, { Dayjs } from "dayjs";
import { GET_PERMITS } from "../../../../routes/permits/getPermits";
import { GET_PERMIT_BY_ID } from "../getPermitById";
import ValidFromTo from "../../../ValidFromTo/ValidFromTo";

type Props = {
  permitId: string;
  onClickNext: () => void;
  onError: (error: string) => void;
  onClose: () => void;
};

type FormData = {
  weaponType: string;
  manufacturer: string;
  model: string;
  weaponNo: string;
  caliber: string;
  journalNo: string;
  validFrom: Dayjs | null;
  validTo: Dayjs | null;
  storageAddress: string;
  imageUrl?: string;
  imageType?: string;
};

const Main: FC<Props> = ({ permitId, onClickNext, onError, onClose }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    weaponType: "",
    manufacturer: "",
    model: "",
    weaponNo: "",
    caliber: "",
    journalNo: "",
    validFrom: dayjs(),
    validTo: dayjs(),
    storageAddress: "",
    imageUrl: "",
    imageType: "",
  });
  const { loading: getPermitLoading } = useGetPermitByIdQuery({
    variables: { id: permitId },
    onError: () => onError("Der skete en fejl ved hentning af tilladelse"),
    onCompleted: (data) => {
      const { getPermitById } = data;
      if (getPermitById) {
        const {
          weaponType,
          manufacturer,
          model,
          weaponNo,
          caliber,
          journalNo,
          validFrom,
          validTo,
          storageAddress,
        } = getPermitById;
        setFormData({
          ...formData,
          weaponType,
          manufacturer,
          model,
          weaponNo,
          caliber,
          journalNo,
          validFrom,
          validTo,
          storageAddress,
        });
      }
    },
  });

  const [edit] = useEditAndUpdatePermitMutation({
    onCompleted: async (data) => {
      if (data) {
        setEditing(false);
        onClose();
      }
    },
    onError: (error) => {
      setEditing(false);
      onError(error.message);
    },
    refetchQueries: [
      { query: GET_PERMITS },
      { query: GET_PERMIT_BY_ID, variables: { id: permitId } },
    ],
  });

  const handleImageUploaded = (key: string, contentType: string) => {
    setFormData({
      ...formData,
      imageUrl: key,
      imageType: contentType,
    });
  };

  const handleImageRemove = () => {
    setFormData({
      ...formData,
      imageUrl: "",
      imageType: "",
    });
  };

  const {
    weaponType,
    manufacturer,
    model,
    weaponNo,
    caliber,
    journalNo,
    validFrom,
    validTo,
    storageAddress,
    imageUrl,
    imageType,
  } = formData;

  const handleSave = () => {
    setEditing(true);
    edit({
      variables: {
        input: {
          permitId,
          caliber,
          imageUrl,
          imageType,
          manufacturer,
          model,
          storageAddress,
          validFrom,
          validTo,
          weaponNo,
          weaponType,
          journalNo,
        },
      },
    });
  };

  const loading = useMemo(
    () => editing || getPermitLoading,
    [editing, getPermitLoading]
  );

  return (
    <>
      <ImageUploadSection
        uploadId={permitId}
        onImageError={onError}
        onImageUploaded={handleImageUploaded}
        onImageUploading={setEditing}
        onImageRemove={handleImageRemove}
        hasImage={!!imageUrl}
      />
      <Button
        color="primary"
        variant="text"
        endIcon={<ArrowForwardIosIcon />}
        onClick={onClickNext}
      >
        Flere Billeder
      </Button>
      <TextField
        label="Våbenart/Type"
        value={weaponType}
        onChange={(e) =>
          setFormData({ ...formData, weaponType: e.target.value })
        }
        fullWidth
        disabled={loading}
      />
      <ManufacturerModel>
        <TextField
          sx={{ flex: 2 }}
          label="Fabrikat"
          value={manufacturer}
          onChange={(e) =>
            setFormData({ ...formData, manufacturer: e.target.value })
          }
          fullWidth
          disabled={loading}
        />
        <TextField
          sx={{ flex: 1 }}
          label="Model"
          value={model}
          onChange={(e) => setFormData({ ...formData, model: e.target.value })}
          fullWidth
          disabled={loading}
        />
      </ManufacturerModel>
      <TextField
        label="Våben nr."
        value={weaponNo}
        onChange={(e) => setFormData({ ...formData, weaponNo: e.target.value })}
        fullWidth
        disabled={loading}
      />
      <TextField
        label="Kaliber"
        value={caliber}
        onChange={(e) => setFormData({ ...formData, caliber: e.target.value })}
        fullWidth
        disabled={loading}
      />
      <TextField
        label="Journal nr."
        value={journalNo}
        onChange={(e) =>
          setFormData({ ...formData, journalNo: e.target.value })
        }
        fullWidth
        disabled={loading}
      />
      <ValidFromTo
        disabled={loading}
        defaultValidFrom={validFrom}
        defaultValidTo={validTo}
        onChange={({ validFrom, validTo }) => {
          setFormData({ ...formData, validFrom, validTo });
        }}
      />
      <TextField
        label="Opbevaringsadresse"
        value={storageAddress}
        onChange={(e) =>
          setFormData({ ...formData, storageAddress: e.target.value })
        }
        fullWidth
        disabled={loading}
      />
      <LoadingButton
        onClick={handleSave}
        loading={loading}
        label="Gem ændringer"
      />
    </>
  );
};

export default Main;
