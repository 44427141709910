import { Alert, Box, ButtonProps } from "@mui/material";
import { FC } from "react";
import LoadingButton from "../LoadingButton/LoadingButton";

type Button = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  // TODO: remove any
  variant?: any;
  color?: any;
};

type Props = {
  buttons: Array<Button>;
  loading?: boolean;
  successMessage?: string;
  errorMessage?: string;
  onCloseSuccessAlert?: () => void;
  onCloseErrorAlert?: () => void;
};

const AlertFooter: FC<Props> = ({
  buttons,
  loading,
  successMessage,
  errorMessage,
  onCloseErrorAlert,
  onCloseSuccessAlert,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {!!successMessage && (
        <Alert
          sx={{ zIndex: 10 }}
          severity="success"
          onClose={onCloseSuccessAlert}
        >
          {successMessage}
        </Alert>
      )}
      {!!errorMessage && (
        <Alert sx={{ zIndex: 10 }} severity="error" onClose={onCloseErrorAlert}>
          {errorMessage}
        </Alert>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        {buttons.map((button) => (
          <LoadingButton
            key={button.label}
            onClick={button.onClick}
            disabled={button.disabled}
            loading={loading}
            label={button.label}
            variant={button.variant || "contained"}
            color={button.color || "primary"}
          />
        ))}
      </Box>
    </Box>
  );
};

export default AlertFooter;
