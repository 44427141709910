import { Box, Typography } from "@mui/material";
import { FC } from "react";
import DetailsCard from "../DetailsCard/DetailsCard";
import ImagePreview from "../ImagePreview/ImagePreview";
import { Permit } from "../../apollo/types";

type Props = {
  permit?: Permit;
};

const PermitDetailsCard: FC<Props> = ({ permit }) => {
  const {
    weaponNo,
    manufacturer,
    model,
    weaponType,
    caliber,
    weaponPart,
    imageUrl,
    imageType,
  } = permit || {};

  return (
    <DetailsCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography mb={1} variant="body1">
            Våben:
          </Typography>
          <Typography variant="body2">Våbennr: {weaponNo}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="body2">{manufacturer}-</Typography>
            <Typography variant="body2">{model}/</Typography>
            <Typography variant="body2">{caliber}</Typography>
          </Box>
          <Typography variant="body2">
            {weaponType}
            {weaponPart && ` (${weaponPart})`}
          </Typography>
        </Box>
        {imageUrl && (
          <ImagePreview
            width={"40%"}
            type={imageType || ""}
            imageUrl={imageUrl || ""}
          />
        )}
      </Box>
    </DetailsCard>
  );
};

export default PermitDetailsCard;
