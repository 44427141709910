import { useState } from "react";
import { useGetImageUploadUrlLazyQuery } from "../apollo/types";
import heic2any from "heic2any";
import { v4 as uuidv4 } from "uuid";

const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10 MB

const useUploadImage = (
  onComplete: (
    key: string,
    contentType: string,
    permitId: string,
    image?: File | null
  ) => Promise<any> | void,
  onError: (message: string) => void
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [getImageUploadUrl, { loading: imageLoading }] =
    useGetImageUploadUrlLazyQuery();

  const handleUploadImage = async (permitId: string, image?: File | null) => {
    try {
      setLoading(true);
      if (image && image.size > MAX_IMAGE_SIZE) {
        onError("Image is too large. Please upload an image less than 10 MB.");
        setLoading(false);
        return;
      }

      // If image is of type heic or heif, convert it to png
      if (
        image &&
        (image.type === "image/heic" || image.type === "image/heif")
      ) {
        try {
          const conversionResult = await heic2any({
            blob: image,
            toType: "image/png",
            quality: 1,
          });
          image = conversionResult as File;
        } catch (err) {
          onError("Kunne ikke konvertere dit billede");
          setLoading(false);
          return;
        }
      }

      let fileName = "";
      let contentType = "";
      if (image) {
        fileName = image.name || `${uuidv4()}-image`;
        contentType = image.type;
      }
      const name = fileName.replace(/\s/g, "");
      // Using Date.now() to get a unique file name
      const uniqueFileName = Date.now() + name;
      const key = `${permitId}/${uniqueFileName}`;

      const signedUrl = await getImageUploadUrl({
        variables: { input: { contentType, key } },
      });

      if (signedUrl.data) {
        try {
          const response = await fetch(signedUrl.data?.getImageUploadUrl.url, {
            method: "PUT",
            headers: {
              "Content-Type": contentType,
            },
            body: image as Blob,
          });
          if (response.ok) {
            await onComplete(key, contentType, permitId, image);
            setLoading(false);
          }
        } catch {
          onError("Kunne ikke uploade dit billede");
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      onError("Kunne ikke uploade dit billede");
      setLoading(false);
    }
  };
  return { handleUploadImage, loading: loading || imageLoading };
};

export default useUploadImage;
