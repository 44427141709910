import { Box, Button, TextField } from "@mui/material";
import useCreateLogStore from "../createLogStore";
import { DatePicker } from "../../Modal/Shared.styles";
import { FC } from "react";
import { Dayjs } from "dayjs";
import TextArea from "../../../TextArea/TextArea";
import {
  MyLogType,
  Permit,
  useCreateLogMutation,
  useMeQuery,
} from "../../../../apollo/types";
import { GET_MY_LOGS_V2 } from "../../../../routes/weaponLogs/queries/getMyLogsV2";
import PermitDetailsCard from "../../../PermitDetailsCard/PermitDetailsCard";
import ValidPeriodDetailsCard from "../../../ValidPeriodDetailsCard/ValidPeriodDetailsCard";

type Props = {
  onClose: () => void;
};

const CreateLog: FC<Props> = ({ onClose }) => {
  const { selectedPermit, createLogInput, setCreateLogInput, setError } =
    useCreateLogStore((state) => state);

  const { validFrom, validTo, journalNo } = selectedPermit || {};
  const { currentAddress, inputDate, note } = createLogInput || {};
  const handleOnChange = (key: string, value: string | Dayjs) => {
    setCreateLogInput({
      ...createLogInput,
      [key]: value,
    });
  };
  const { data: me } = useMeQuery({
    onCompleted: (data) => {
      if (data?.me?.address) {
        handleOnChange("currentAddress", data.me.address);
      }
    },
  });

  const [createLog] = useCreateLogMutation({
    onError: (err) => setError(err.message),
    onCompleted: (data) => {
      if (data) {
        onClose();
      }
    },
    refetchQueries: [
      {
        query: GET_MY_LOGS_V2,
        variables: {
          input: {
            logType: MyLogType.Active,
          },
        },
      },
    ],
  });

  const handleOnClick = () => {
    selectedPermit &&
      me?.me &&
      !!currentAddress &&
      createLog({
        variables: {
          input: {
            currentAddress,
            inputDate,
            permitId: selectedPermit._id,
            receivingUserId: me.me._id,
            note,
          },
        },
      });
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <PermitDetailsCard permit={selectedPermit as Permit} />
        <ValidPeriodDetailsCard
          validFrom={validFrom || ""}
          validTo={validTo || ""}
          journalNo={journalNo || ""}
        />
      </Box>
      <DatePicker
        label="Dato"
        inputFormat="DD/MM/YYYY"
        value={inputDate}
        onChange={(value: Dayjs) => handleOnChange("inputDate", value)}
        renderInput={(params: any) => <TextField {...params} />}
      />
      <TextField
        label="Adresse"
        value={currentAddress}
        onChange={(e) => handleOnChange("currentAddress", e.target.value)}
        fullWidth
      />
      <TextArea
        minRows={3}
        placeholder="Note"
        value={note || ""}
        onChange={(e) => handleOnChange("note", e.target.value)}
      />
      <Button
        disabled={!selectedPermit || !me?.me || !currentAddress}
        variant="contained"
        onClick={handleOnClick}
      >
        Opret ny log
      </Button>
    </>
  );
};
export default CreateLog;
