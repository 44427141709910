import { FC } from "react";
import { Box, Container, Typography } from "@mui/material";
import ChangePassword from "./ChangePassword/ChangePassword";
import ChangeEmail from "./ChangeEmail/ChangeEmail";
import Address from "./Address/Address";
import GetAcummulatedLogs from "./GetAccumulatedLogs/GetAcummulatedLogs";

const Settings: FC = () => (
  <Container maxWidth="sm">
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        paddingBottom: 4,
      }}
    >
      <Typography variant="h5">Bruger indstillinger</Typography>
      <Address />
      <ChangePassword />
      <ChangeEmail />
      <GetAcummulatedLogs />
    </Box>
  </Container>
);

export default Settings;
