import { IconButton, TableCell, TableRow } from "@mui/material";
import { grey } from "@mui/material/colors";
import dayjs from "dayjs";
import { FC, useRef, useState } from "react";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import MoreMenu from "../MoreMenu/MoreMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BaseRowLogFragment } from "../../../../../apollo/types";

interface Props {
  row: BaseRowLogFragment;
  setSelectedLogId: (logId: string) => void;
  onClickSeeDetails: () => void;
}

const SubLogTableRow: FC<Props> = ({
  row,
  setSelectedLogId,
  onClickSeeDetails,
}) => {
  const ref: any = useRef();
  const [showMore, setShowMore] = useState<boolean>(false);
  useOnClickOutside(ref, () => setShowMore(false));
  const {
    _id: id,
    inputDate,
    note,
    givingUser: { fullName: givingUserName },
    receivingUser: { fullName: receivingUserName },
  } = row;

  return (
    <TableRow
      sx={{
        background: grey[100],
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row">
        -
      </TableCell>
      <TableCell component="th" scope="row">
        {dayjs(inputDate).utc(true).format("DD/MM/YYYY")}
      </TableCell>
      <TableCell component="th" scope="row" />
      <TableCell component="th" scope="row" />
      <TableCell>{givingUserName}</TableCell>
      <TableCell>{receivingUserName}</TableCell>
      <TableCell>
        {note || (
          <IconButton
            aria-label="Remove image button"
            onClick={() => setSelectedLogId(id)}
            onMouseDown={() => setSelectedLogId(id)}
          >
            <NoteAddIcon color="primary" />
          </IconButton>
        )}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        <div ref={ref}>
          <>
            <IconButton
              aria-label="see more sub permit options"
              onClick={() => setShowMore((prevState) => !prevState)}
            >
              <MoreVertIcon />
            </IconButton>
            {showMore && <MoreMenu onClickSeeDetails={onClickSeeDetails} />}
          </>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SubLogTableRow;
