import { FC } from "react";
import { Box, CircularProgress } from "@mui/material";

const LoadingScreen: FC = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
    }}
  >
    <CircularProgress color={"info"} size={120} />
  </Box>
);

export default LoadingScreen;
