import { FC, useEffect } from "react";
import { useGetPermitsByWeaponNoLazyQuery } from "../../../apollo/types";
import Modal from "../Modal/Modal";
import useCreateLogStore from "./createLogStore";
import SearchPermit from "./Steps/SearchPermit";
import PermitList from "./Steps/PermitList";
import CreateLog from "./Steps/CreateLog";
import ChooseType from "./Steps/ChooseType";
import AddManualLog from "./Steps/AddManualLog/AddManualLog";

type Props = {
  open: boolean;
  onClose: () => void;
};

const CreateLogModal: FC<Props> = ({ open, onClose }) => {
  const {
    currentStep,
    setCurrentStep,
    selectedPermit,
    setSelectedPermit,
    resetStore,
    error,
    setError,
  } = useCreateLogStore((state) => state);

  const handleOnClose = () => {
    resetStore();
    onClose();
  };

  const [getPermits, { data, loading }] = useGetPermitsByWeaponNoLazyQuery({
    onError: (err) => setError(err.message),
    fetchPolicy: "network-only",
  });

  const { getPermitsByWeaponNo } = data || {};

  useEffect(() => {
    if (getPermitsByWeaponNo?.length === 1) {
      setSelectedPermit(getPermitsByWeaponNo[0]);
      setCurrentStep("CreateLog");
    } else if (getPermitsByWeaponNo && getPermitsByWeaponNo.length > 1) {
      setCurrentStep("PermitList");
    }
  }, [data]);

  const getTitle = (): string => {
    switch (currentStep) {
      case "ChooseType": {
        return "Vælg log type";
      }
      case "AddManualLog": {
        return "Opret manuel log";
      }
      case "SearchPermit":
        return "Søg journal nr";
      case "PermitList":
        return "Vælg tilladelse";
      case "CreateLog":
        return "Opret log";
      default:
        return "";
    }
  };

  const goBack = () => {
    switch (currentStep) {
      case "AddManualLog":
      case "SearchPermit": {
        setCurrentStep("ChooseType");
        break;
      }
      case "PermitList": {
        setCurrentStep("SearchPermit");
        break;
      }
      case "CreateLog": {
        setCurrentStep("PermitList");
        break;
      }
      default:
        return undefined;
    }
    return undefined;
  };

  return (
    <Modal
      error={error}
      onDismissError={() => setError("")}
      open={open}
      onClose={handleOnClose}
      title={getTitle()}
      goBack={currentStep === "ChooseType" ? undefined : () => goBack()}
      width={680}
    >
      {currentStep === "ChooseType" ? (
        <ChooseType />
      ) : currentStep === "AddManualLog" ? (
        <AddManualLog onClose={handleOnClose} />
      ) : currentStep === "SearchPermit" ? (
        <SearchPermit
          onClickGetPermits={(weaponNo) =>
            getPermits({ variables: { weaponNo } })
          }
        />
      ) : currentStep === "PermitList" ? (
        <PermitList permits={getPermitsByWeaponNo} />
      ) : currentStep === "CreateLog" && selectedPermit ? (
        <CreateLog onClose={handleOnClose} />
      ) : null}
    </Modal>
  );
};

export default CreateLogModal;
