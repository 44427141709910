import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

import { setContext } from "apollo-link-context";

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${process.env.API_KEY}`,
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.API_URL,
  credentials: "include",
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
