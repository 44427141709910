import {
  MyLogType,
  PrintLogFragment,
  PrintLogPermitFragment,
  useGetMyLogsPrintLazyQuery,
} from "../../../apollo/types";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

export enum PrintType {
  Pdf = "pdf",
  Xlsx = "xlsx",
  Csv = "csv",
}

const useGetPrint = () => {
  const [getMyLogsPrint] = useGetMyLogsPrintLazyQuery({
    fetchPolicy: "network-only",
  });

  const fetchLogs = async (logType: MyLogType): Promise<PrintLogFragment[]> => {
    const logs = await getMyLogsPrint({
      variables: {
        input: {
          logType,
        },
      },
    });
    return logs.data?.getMyLogsV2 || [];
  };

  type FlatObject = {
    [key: string]: string | number | boolean | null;
  };
  const flattenObject = <T extends Record<string, any>>(obj: T): FlatObject => {
    const flattened: FlatObject = {};

    Object.keys(obj).forEach((key) => {
      const value = obj[key as keyof T];

      // Special combined logic for permit fields
      if (key === "permit") {
        const permit = value as PrintLogPermitFragment;
        flattened[
          "weaponInfo"
        ] = `${permit.weaponType} / ${permit.caliber} / ${permit.manufacturer}-${permit.model}`;
      }

      // Generic object flattening logic (handles nested objects)
      if (typeof value === "object" && value !== null && key !== "subLogs") {
        const flatSubObj = flattenObject(value as any);
        for (let subKey in flatSubObj) {
          flattened[`${key}.${subKey}`] = flatSubObj[subKey];
        }
      } else if (key !== "permit" && key !== "subLogs") {
        // Ensure we don't add the whole permit or subLogs object as a string
        flattened[key] = value as any;
      }
    });

    // Set the "from" and "to" fields
    flattened["from"] = obj.givingUser?.fullName;
    flattened["to"] = obj.receivingUser?.fullName;

    return flattened;
  };

  const selectedFields = [
    "serialNo",
    "inputDate",
    "weaponInfo",
    "permit.weaponNo",
    "from",
    "to",
    "note",
    "permit.journalNo",
  ];

  const headerRenamingMap: Record<string, string> = {
    serialNo: "Løbenr.",
    inputDate: "Dato",
    weaponInfo: "Våben",
    "permit.weaponNo": "Våben nr.",
    from: "Fra",
    to: "Til",
    note: "Note",
    "permit.journalNo": "Journal nr.",
  };

  const getFileName = (logType: MyLogType) =>
    logType === MyLogType.Ended
      ? "afsluttede-logs"
      : logType === MyLogType.Active
      ? "igangværende-logs"
      : "alle-logs";

  const getFlattenedData = (logs: PrintLogFragment[]): FlatObject[] => {
    const flattenedLogs: FlatObject[] = [];

    logs.forEach((log) => {
      // Add main log
      flattenedLogs.push(flattenObject(log));

      // Add subLogs
      if (log.subLogs && log.subLogs.length > 0) {
        log.subLogs.forEach((subLog) => {
          flattenedLogs.push(flattenObject(subLog));
        });
      }
    });

    return flattenedLogs;
  };

  const getPdf = (
    logs: PrintLogFragment[],
    printerName: string,
    companyName: string,
    vat: string,
    fileName: string
  ) => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.setFontSize(10); // Smaller font size for metadata
    let yPosition = 10; // Starting Y position

    doc.text(`Udskrift den: ${dayjs().format("DD/MM/YYYY")}`, 10, 10);
    yPosition += 10;
    doc.text(`Udskrevet af: ${printerName}`, 10, 20);
    yPosition += 10;
    doc.text(`${companyName}`, 10, 30);
    yPosition += 10;
    doc.text(`CVR: ${vat}`, 10, 40);
    yPosition += 10;

    const headers = selectedFields.map(
      (field) => headerRenamingMap[field] || field
    );

    const values = getFlattenedData(logs).map((obj) =>
      selectedFields.map((field) =>
        // Format inputDate field to DD/MM/YYYY
        field === "inputDate"
          ? dayjs(obj[field] as any).format("DD/MM/YYYY")
          : obj[field]
      )
    );

    autoTable(doc, {
      head: [headers],
      body: values,
      startY: yPosition,
      headStyles: {
        fillColor: [31, 52, 21],
        textColor: [255, 255, 255],
      },
    });

    doc.save(`${fileName}.pdf`);
  };

  const getSheet = (
    logs: PrintLogFragment[],
    printerName: string,
    companyName: string,
    vat: string,
    fileName: string,
    printType: PrintType
  ) => {
    const flattenedData = getFlattenedData(logs).map((obj) => {
      const result: Record<string, any> = {};
      selectedFields.forEach((field) => {
        result[headerRenamingMap[field] || field] = obj[field];
        // Format inputDate field to DD/MM/YYYY
        if (field === "inputDate") {
          result[headerRenamingMap[field] || field] = dayjs(
            obj[field] as any
          ).format("DD/MM/YYYY");
        }
      });
      return result;
    });

    const firstColumnName =
      headerRenamingMap[selectedFields[0]] || selectedFields[0];

    const metadata = [
      { [firstColumnName]: `Udskrift den: ${dayjs().format("DD/MM/YYYY")}` },
      { [firstColumnName]: `Udskrevet af: ${printerName}` },
      { [firstColumnName]: `${companyName}` },
      { [firstColumnName]: `CVR: ${vat}` },
    ];

    const data = [...flattenedData, {}, ...metadata];

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Write the workbook to a binary format (xlsx) and trigger a download
    if (printType === PrintType.Csv) {
      XLSX.writeFile(wb, `${fileName}.csv`, { bookType: "csv" });
    } else {
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    }
  };

  const getPrint = async (
    logType: MyLogType,
    printType: PrintType,
    printerName: string,
    companyName: string,
    vat: string
  ) => {
    // Fetch correct logs according to logType
    const logs = await fetchLogs(logType);
    const fileName = getFileName(logType);
    // Feed the logs to the print function according to printType
    if (printType === PrintType.Pdf) {
      getPdf(logs, printerName, companyName, vat, fileName);
    } else {
      getSheet(logs, printerName, companyName, vat, fileName, printType);
    }
  };

  return { getPrint };
};

export default useGetPrint;
