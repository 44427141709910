const calibers = [
  "11",
  "11,35",
  "11,43 x 59 R",
  "11,44",
  "12",
  "12/65",
  "12/70",
  "12/76",
  "12/89",
  "16",
  "16 MM",
  "16,9",
  "16/65",
  "16/70",
  "16/85",
  "17 HMR",
  "17,5",
  "20",
  "20/70",
  "20/76",
  "22",
  "22 HORNET",
  "22 LR",
  "22 WMR",
  "222 REM",
  "22-250 REM",
  "223 REM",
  "243 WIN",
  "25-06 REM",
  "25-20 WCF",
  "26,5",
  "264",
  "270",
  "270 WBY MAG",
  "270 WIN",
  "28/70",
  "280 REM",
  "284",
  "30",
  "30/7,62",
  "300 BLACKOUT",
  "300 NORMA MAG",
  "300 PRC",
  "300 WIN MAG",
  "30-06 SPRINGFIELD",
  "303 BRITISH",
  "303 SAVAGE",
  "30-30 WIN",
  "308 WIN",
  "308 WIN MAG",
  "338",
  "338 LAPUA MAG",
  "35 WHELEN",
  "357 MAG",
  "358",
  "366",
  "375 CT",
  "375 H&H MAG",
  "375 RUGER",
  "38",
  "38/357mag",
  "4",
  "4,5",
  "40 S&W",
  "410",
  "416 RIGBY",
  "44",
  "45",
  "45 ACP",
  "455",
  "45-70 GOVT",
  "458 EXPRESS",
  "458 WIN MAG",
  "45-90",
  "5 REM MAG",
  "5,5",
  "5,6 x 57",
  "5,6 x 57 R",
  "6 CREEDMOOR",
  "6 MM BR",
  "6 MM PLATZ",
  "6 MM XC",
  "6 x 70 R",
  "6,2 x 48 R",
  "6,35",
  "6,5",
  "6,5 CREEDMOOR",
  "6,5 x 53 R",
  "6,5 x 55",
  "6,5 x 55 SE",
  "6,5 x 57",
  "6xc",
  "7 REM MAG",
  "7 Remington Short Action Ultra Magnum",
  "7 x 33",
  "7 x 56 R",
  "7 x 57",
  "7 x 57 R",
  "7 x 61",
  "7 x 64",
  "7 x 65 R",
  "7,5",
  "7,5 x 55",
  "7,62",
  "7,62 x 51",
  "7,62 x 54 R",
  "7,63 x 25",
  "7,65",
  "7-08 REM",
  "7-30 WATERS",
  "8",
  "8 x 57",
  "8 x 57 JS",
  "8 x 58 RD",
  "8 x 68 S",
  "8,5 x 55 BLASER",
  "9",
  "9 LUGER",
  "9 MM PLATZ",
  "9 x 19",
  "9,3 x 62",
  "9,3 x 74 R",
  "Jf. pibe",
];

export default calibers;
