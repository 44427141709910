import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { FC, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Paths } from "../../routes/router";
import { useMeQuery, UserType } from "../../apollo/types";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import UserMenu from "./UserMenu";

const TopBar: FC = () => {
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);

  const location = useLocation();
  const { data: me } = useMeQuery();
  const ref = useRef(null);

  useOnClickOutside(ref, () => setShowUserMenu(false));

  return (
    <Box sx={{ flexGrow: 1, background: "#E9DFB5" }}>
      <AppBar position="static" color={"inherit"}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: 16 }}>
            <Button
              component={Link}
              to={Paths.PERMITS}
              variant={
                location.pathname === `/${Paths.PERMITS}`
                  ? "contained"
                  : "outlined"
              }
            >
              Tilladelser
            </Button>
            {me?.me?.userType === UserType.Corporate && (
              <Button
                component={Link}
                to={Paths.LOGS}
                variant={
                  location.pathname === `/${Paths.LOGS}`
                    ? "contained"
                    : "outlined"
                }
              >
                Logs
              </Button>
            )}
          </div>
          <Box
            onClick={() => setShowUserMenu(!showUserMenu)}
            ref={ref}
            sx={{
              display: "flex",
              gap: 16,
              alignItems: "center",
              position: "relative",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            <Typography>
              {me?.me?.firstName} {me?.me?.lastName}
            </Typography>
            {showUserMenu && <UserMenu />}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
