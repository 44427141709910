import { MyLogType, useEndLogMutation } from "../../../apollo/types";
import { GET_MY_LOGS_V2 } from "../../../routes/weaponLogs/queries/getMyLogsV2";
import useEndLogStore from "./endLogStore";

const useEndLog = (onCompleted: () => void) => {
  const endLogData = useEndLogStore((state) => state.endLogData);
  const endLogType = useEndLogStore((state) => state.endType);
  const { inputDate, note } = endLogData;
  const [end, { loading }] = useEndLogMutation({
    onCompleted: () => onCompleted(),
    refetchQueries: [
      {
        query: GET_MY_LOGS_V2,
        variables: {
          input: {
            logType: MyLogType.Active,
          },
        },
      },
    ],
  });
  const endLog = (logId: string, userId: string) => {
    end({
      variables: {
        input: {
          endLogType,
          rootLogId: logId,
          inputDate,
          note,
          userId,
          firstName: endLogData.thirdParty.firstName,
          lastName: endLogData.thirdParty.lastName,
          email: endLogData.thirdParty.email,
          address: endLogData.thirdParty.address,
          journalNo: endLogData.thirdParty.journalNo,
          imageType: endLogData.thirdParty.imageType,
          imageUrl: endLogData.thirdParty.imageUrl,
          validFrom: endLogData.thirdParty.validFrom,
          validTo: endLogData.thirdParty.validTo,
        },
      },
    });
  };

  return { endLog, loading };
};

export default useEndLog;
