// TODO: Refactor this component when time!!
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FC, useMemo, useState } from "react";
import {
  PermitUser,
  useCreatePermitMutation,
  useEditPermitMutation,
} from "../../../apollo/types";
import { GET_PERMITS } from "../../../routes/permits/getPermits";
import Modal from "../Modal/Modal";
import { ManufacturerModel } from "../Modal/Shared.styles";
import { Footer, StepTwo } from "./CreatePermitModal.styles";
import useUploadImage from "../../../hooks/useUploadImage";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ImageUploaded from "../../ImageUploadSection/ImageUploaded";
import ValidFromTo from "../../ValidFromTo/ValidFromTo";
import FuzzySearchSelect from "../../FuzzySearchSelect/FuzzySearchSelect";

interface Props {
  open: boolean;
  onClose: () => void;
  id?: string;
}

const CreatePermitModal: FC<Props> = ({ open, onClose, id }) => {
  const [caliber, setCaliber] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [manufacturer, setManufacturer] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [storageAddress, setStorageAddress] = useState<string>("");
  const [validFrom, setValidFrom] = useState<Dayjs | null>(dayjs());
  const [validTo, setValidTo] = useState<Dayjs | null>(dayjs());
  const [weaponNo, setWeaponNo] = useState<string>("");
  const [weaponType, setWeaponType] = useState<string>("");
  const [permitUser, setPermitUser] = useState<PermitUser>(PermitUser.Owner);
  const [journalNo, setJournalNo] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [step, setStep] = useState<"1" | "2">("1");
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const [create, { loading: createLoading }] = useCreatePermitMutation({
    onCompleted: async (data) => {
      if (data && image) {
        await handleUploadImage(data.createPermit._id, image);
      }
      handleOnClose();
    },
    onError: (error) => setError(error.message),
    refetchQueries: [{ query: GET_PERMITS }],
  });

  const [edit, { loading: editLoading }] = useEditPermitMutation();

  const { handleUploadImage, loading: imageLoading } = useUploadImage(
    (key, contentType, permitId) =>
      edit({
        variables: {
          input: { permitId, imageUrl: key, imageType: contentType },
        },
      }),
    (err) => setError(err)
  );

  const loading = useMemo(
    () => createLoading || editLoading || imageLoading,
    [createLoading, editLoading, imageLoading]
  );

  const handleOnClose = () => {
    setCaliber("");
    setImage(null);
    setManufacturer("");
    setModel("");
    setStorageAddress("");
    setValidFrom(dayjs());
    setValidTo(dayjs());
    setWeaponNo("");
    setWeaponType("");
    setPermitUser(PermitUser.Owner);
    setJournalNo("");
    setStep("1");
    onClose();
    setError("");
  };

  const handleLeftButtonClick = () => {
    if (step === "1") {
      handleOnClose();
    } else {
      setStep("1");
    }
  };

  const handleRightButtonClick = () => {
    if (step === "1") {
      setStep("2");
    } else {
      create({
        variables: {
          input: {
            caliber,
            manufacturer,
            model,
            storageAddress,
            validFrom,
            validTo,
            weaponNo,
            weaponType,
            permitUser,
            journalNo,
          },
        },
      });
    }
  };

  const getText = (): { caption: string; header: string } => {
    const text = {
      caption:
        "Upload din tilladelse til din digitale våbenbog efter du har ansøgt og modtaget en officiel tilladelse fra politiet.",
      header: "Ny tilladelse",
    };
    if (step === "1") {
      return text;
    } else {
      if (permitUser === PermitUser.Owner) {
        text.caption =
          "Primær tilladelse er en tilladelse hvor du er ejeren af våbenet";
        text.header = "Primær tilladelse";
      } else {
        text.caption =
          "Bære tilladelse er en tilladelse hvor du har tilladelse til at bære våbenet";
        text.header = "Bære tilladelse";
      }
      return text;
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setFileInputKey(Date.now());
  };
  return (
    <Modal
      error={error}
      onDismissError={() => setError("")}
      open={open}
      onClose={handleOnClose}
      title={getText().header}
      width={680}
    >
      <Typography variant="caption">{getText().caption}</Typography>
      {step === "1" ? (
        <FormControl>
          <FormLabel id="permit-type-radio-buttons-group-label">
            Hvilken slags tilladelse vil du uploade?
          </FormLabel>
          <RadioGroup
            onChange={(e) => setPermitUser(e.target.value as PermitUser)}
            aria-labelledby="permit-type-radio-buttons-group-label"
            defaultValue={PermitUser.Owner}
            name="permit-type-radio-buttons-group"
          >
            <FormControlLabel
              value={PermitUser.Owner}
              control={<Radio />}
              label="Primær tilladelse"
            />
            <FormControlLabel
              value={PermitUser.User}
              control={<Radio />}
              label="Bære tilladelse"
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <StepTwo>
          <Button
            component="label"
            color="primary"
            variant="contained"
            disabled={loading}
            endIcon={<InsertDriveFileOutlinedIcon />}
          >
            Upload våbentilladelse
            <input
              key={fileInputKey}
              type="file"
              hidden
              accept="image/*, .pdf, .heic, .heif, image/heic, image/heif"
              onChange={(e) => {
                setImage(e.target.files && e.target.files[0]);
              }}
              disabled={loading}
            />
          </Button>
          {!!image && (
            <ImageUploaded
              imageName={image.name}
              onClickRemove={handleRemoveImage}
            />
          )}
          <TextField
            label="Våbenart/Type"
            value={weaponType}
            onChange={(e) => setWeaponType(e.target.value)}
            fullWidth
            disabled={loading}
          />
          <ManufacturerModel>
            <FuzzySearchSelect
              sx={{ flex: 2 }}
              label="Fabrikat"
              selectType="manufacturers"
              disabled={loading}
              fullWidth
              onSelectResult={(value) => setManufacturer(value)}
            />
            <TextField
              sx={{ flex: 1 }}
              label="Model"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              fullWidth
              disabled={loading}
            />
          </ManufacturerModel>
          <TextField
            label="Våben nr."
            value={weaponNo}
            onChange={(e) => setWeaponNo(e.target.value)}
            fullWidth
            disabled={loading}
          />
          <FuzzySearchSelect
            label="Kaliber"
            selectType="calibers"
            disabled={loading}
            fullWidth
            onSelectResult={(value) => setCaliber(value)}
          />
          <TextField
            label="Journal/tilladelses nr."
            value={journalNo}
            onChange={(e) => setJournalNo(e.target.value)}
            fullWidth
            disabled={loading}
          />
          <ValidFromTo
            disabled={loading}
            defaultValidFrom={validFrom}
            defaultValidTo={validTo}
            onChange={({ validFrom, validTo }) => {
              setValidFrom(validFrom);
              setValidTo(validTo);
            }}
          />
          <TextField
            disabled={loading}
            label="Opbevaringsadresse"
            value={storageAddress}
            onChange={(e) => setStorageAddress(e.target.value)}
            fullWidth
          />
        </StepTwo>
      )}
      <Footer>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={handleLeftButtonClick}
        >
          {step === "1" ? "Luk" : step === "2" ? "Tilbage" : ""}
        </Button>
        <Button
          variant="contained"
          onClick={handleRightButtonClick}
          disabled={
            step === "2" &&
            (!image ||
              !caliber ||
              !manufacturer ||
              !model ||
              !storageAddress ||
              !validFrom ||
              !validTo ||
              !weaponNo ||
              !journalNo ||
              loading)
          }
        >
          {loading ? (
            <CircularProgress color={"info"} size={20} />
          ) : step === "1" ? (
            "Næste"
          ) : step === "2" ? (
            "Gem"
          ) : (
            ""
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default CreatePermitModal;
