import { FC, useState } from "react";
import Modal from "../Modal/Modal";
import useGetPrint, { PrintType } from "./useGetPrint";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import LoadingButton from "../../LoadingButton/LoadingButton";
import { MyLogType } from "../../../apollo/types";

interface Props {
  open: boolean;
  onClose: () => void;
}

const PrintLogsModal: FC<Props> = ({ open, onClose }) => {
  const [error, setError] = useState<string>("");
  const [printerName, setPrinterName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [vat, setVat] = useState<string>("");
  const [printType, setPrintType] = useState<PrintType>(PrintType.Pdf);
  const [logType, setLogType] = useState<MyLogType>(MyLogType.Active);

  const handleOnClose = () => {
    onClose();
  };

  const { getPrint } = useGetPrint();

  const handleGetPrint = () => {
    getPrint(logType, printType, printerName, companyName, vat);
  };

  return (
    <Modal
      error={error}
      onDismissError={() => setError("")}
      open={open}
      onClose={handleOnClose}
      title={`Print logs`}
      width={680}
    >
      <TextField
        label="Navn på ansvarshavende"
        value={printerName}
        onChange={(e) => setPrinterName(e.target.value)}
        fullWidth
      />
      <TextField
        label="Navn på virksomhed"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        fullWidth
      />
      <TextField
        label="Virksomhedens CVR nr."
        value={vat}
        onChange={(e) => setVat(e.target.value)}
        fullWidth
      />
      <FormControl>
        <FormLabel id="log-type-radio-buttons-group-label">Log type</FormLabel>
        <RadioGroup
          onChange={(e) => setLogType(e.target.value as MyLogType)}
          aria-labelledby="log-type-radio-buttons-group-label"
          defaultValue={MyLogType.Active}
          name="log-type-radio-buttons-group"
        >
          <FormControlLabel
            value={MyLogType.Active}
            control={<Radio color="secondary" />}
            label="Igangværende"
          />
          <FormControlLabel
            value={MyLogType.Ended}
            control={<Radio color="secondary" />}
            label="Afsluttede"
          />
          <FormControlLabel
            value={MyLogType.All}
            control={<Radio color="secondary" />}
            label="Alle"
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="print-type-radio-buttons-group-label">Format</FormLabel>
        <RadioGroup
          onChange={(e) => setPrintType(e.target.value as PrintType)}
          aria-labelledby="print-type-radio-buttons-group-label"
          defaultValue={PrintType.Pdf}
          name="print-type-radio-buttons-group"
        >
          <FormControlLabel
            value={PrintType.Pdf}
            control={<Radio color="secondary" />}
            label=".pdf"
          />
          <FormControlLabel
            value={PrintType.Xlsx}
            control={<Radio color="secondary" />}
            label="excel"
          />
          <FormControlLabel
            value={PrintType.Csv}
            control={<Radio color="secondary" />}
            label="csv"
          />
        </RadioGroup>
      </FormControl>
      <LoadingButton
        label="Print"
        loading={false}
        onClick={() => handleGetPrint()}
        disabled={!printerName || !vat}
      />
    </Modal>
  );
};

export default PrintLogsModal;
