import { styled } from "@mui/material";

interface DotProps {
  size: number;
  color: string;
}

export const Dot = styled("span")<DotProps>(({ size, color }) => ({
  height: `${size}px`,
  width: `${size}px`,
  backgroundColor: color,
  borderRadius: "50%",
  display: "inline-block",
}));
